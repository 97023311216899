import { useNavigate } from 'react-router-dom'
import { Auth } from "aws-amplify"


function NotFountPage() {
    const navigate = useNavigate()

    return (<>
        <h1>Recurso no disponible</h1>
        <div>La página no se ha encontrado o no tienes permisos para visuarlizarlo</div>
        <button onClick={(() => { navigate('/') })}>Regresar</button>
        <button onClick={(() => { Auth.signOut() })}>Cerrar sesión</button>

    </>)
}

export default NotFountPage