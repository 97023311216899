/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExportLambda = /* GraphQL */ `
  mutation CreateExportLambda($input: CreateExportInput) {
    createExportLambda(input: $input) {
      id
      name
      dateStart
      dateEnd
      id_status
      status_redshift {
        id
        code
        description
      }
      status
      count
      audience {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      destiny
      transfered
      is_deleted
      error
      description
      createdAt
      updatedAt
      exportUserId
    }
  }
`;
export const updateExportLambda = /* GraphQL */ `
  mutation UpdateExportLambda($input: UpdateExportInput) {
    updateExportLambda(input: $input) {
      id
      name
      dateStart
      dateEnd
      id_status
      status_redshift {
        id
        code
        description
      }
      status
      count
      audience {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      destiny
      transfered
      is_deleted
      error
      description
      createdAt
      updatedAt
      exportUserId
    }
  }
`;
export const createUserCustom = /* GraphQL */ `
  mutation CreateUserCustom($input: createUserInput) {
    createUserCustom(input: $input) {
      id
      name
      lastName
      job
      rol
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          isPublic
          count
          is_deleted
          error
          description
          createdAt
          updatedAt
          userAudiencesId
          audienceUserId
          audienceClmId
        }
        nextToken
      }
      is_deleted
      username
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const customCreateClm = /* GraphQL */ `
  mutation CustomCreateClm($input: clmInput) {
    customCreateClm(input: $input) {
      id
      name
      status
      date
      createdAt
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      adiditionalAttributes
      matchedSubscribers
      recordCount
      pathFile
      is_deleted
      updatedAt
      cLMUserId
      cLMCompanyId
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      lastName
      job
      rol
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          isPublic
          count
          is_deleted
          error
          description
          createdAt
          updatedAt
          userAudiencesId
          audienceUserId
          audienceClmId
        }
        nextToken
      }
      is_deleted
      username
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      lastName
      job
      rol
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          isPublic
          count
          is_deleted
          error
          description
          createdAt
          updatedAt
          userAudiencesId
          audienceUserId
          audienceClmId
        }
        nextToken
      }
      is_deleted
      username
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      lastName
      job
      rol
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      audiences {
        items {
          id
          name
          isPublic
          count
          is_deleted
          error
          description
          createdAt
          updatedAt
          userAudiencesId
          audienceUserId
          audienceClmId
        }
        nextToken
      }
      is_deleted
      username
      createdAt
      updatedAt
      userCompanyId
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      is_deleted
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      is_deleted
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      is_deleted
      createdAt
      updatedAt
    }
  }
`;
export const createAudience = /* GraphQL */ `
  mutation CreateAudience(
    $input: CreateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    createAudience(input: $input, condition: $condition) {
      id
      name
      filters {
        key
        type_filter
        value_filter
        type_behaviour
        description_filter
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      isPublic
      count
      exports {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      clm {
        id
        name
        status
        date
        createdAt
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        adiditionalAttributes
        matchedSubscribers
        recordCount
        pathFile
        is_deleted
        updatedAt
        cLMUserId
        cLMCompanyId
      }
      is_deleted
      error
      description
      createdAt
      updatedAt
      userAudiencesId
      audienceUserId
      audienceClmId
    }
  }
`;
export const updateAudience = /* GraphQL */ `
  mutation UpdateAudience(
    $input: UpdateAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    updateAudience(input: $input, condition: $condition) {
      id
      name
      filters {
        key
        type_filter
        value_filter
        type_behaviour
        description_filter
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      isPublic
      count
      exports {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      clm {
        id
        name
        status
        date
        createdAt
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        adiditionalAttributes
        matchedSubscribers
        recordCount
        pathFile
        is_deleted
        updatedAt
        cLMUserId
        cLMCompanyId
      }
      is_deleted
      error
      description
      createdAt
      updatedAt
      userAudiencesId
      audienceUserId
      audienceClmId
    }
  }
`;
export const deleteAudience = /* GraphQL */ `
  mutation DeleteAudience(
    $input: DeleteAudienceInput!
    $condition: ModelAudienceConditionInput
  ) {
    deleteAudience(input: $input, condition: $condition) {
      id
      name
      filters {
        key
        type_filter
        value_filter
        type_behaviour
        description_filter
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      isPublic
      count
      exports {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      clm {
        id
        name
        status
        date
        createdAt
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        adiditionalAttributes
        matchedSubscribers
        recordCount
        pathFile
        is_deleted
        updatedAt
        cLMUserId
        cLMCompanyId
      }
      is_deleted
      error
      description
      createdAt
      updatedAt
      userAudiencesId
      audienceUserId
      audienceClmId
    }
  }
`;
export const createExport = /* GraphQL */ `
  mutation CreateExport(
    $input: CreateExportInput!
    $condition: ModelExportConditionInput
  ) {
    createExport(input: $input, condition: $condition) {
      id
      name
      dateStart
      dateEnd
      id_status
      status_redshift {
        id
        code
        description
      }
      status
      count
      audience {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      destiny
      transfered
      is_deleted
      error
      description
      createdAt
      updatedAt
      exportUserId
    }
  }
`;
export const updateExport = /* GraphQL */ `
  mutation UpdateExport(
    $input: UpdateExportInput!
    $condition: ModelExportConditionInput
  ) {
    updateExport(input: $input, condition: $condition) {
      id
      name
      dateStart
      dateEnd
      id_status
      status_redshift {
        id
        code
        description
      }
      status
      count
      audience {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      destiny
      transfered
      is_deleted
      error
      description
      createdAt
      updatedAt
      exportUserId
    }
  }
`;
export const deleteExport = /* GraphQL */ `
  mutation DeleteExport(
    $input: DeleteExportInput!
    $condition: ModelExportConditionInput
  ) {
    deleteExport(input: $input, condition: $condition) {
      id
      name
      dateStart
      dateEnd
      id_status
      status_redshift {
        id
        code
        description
      }
      status
      count
      audience {
        items {
          id
          audienceID
          exportID
          createdAt
          updatedAt
        }
        nextToken
      }
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      destiny
      transfered
      is_deleted
      error
      description
      createdAt
      updatedAt
      exportUserId
    }
  }
`;
export const createCLM = /* GraphQL */ `
  mutation CreateCLM(
    $input: CreateCLMInput!
    $condition: ModelCLMConditionInput
  ) {
    createCLM(input: $input, condition: $condition) {
      id
      name
      status
      date
      createdAt
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      adiditionalAttributes
      matchedSubscribers
      recordCount
      pathFile
      is_deleted
      updatedAt
      cLMUserId
      cLMCompanyId
    }
  }
`;
export const updateCLM = /* GraphQL */ `
  mutation UpdateCLM(
    $input: UpdateCLMInput!
    $condition: ModelCLMConditionInput
  ) {
    updateCLM(input: $input, condition: $condition) {
      id
      name
      status
      date
      createdAt
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      adiditionalAttributes
      matchedSubscribers
      recordCount
      pathFile
      is_deleted
      updatedAt
      cLMUserId
      cLMCompanyId
    }
  }
`;
export const deleteCLM = /* GraphQL */ `
  mutation DeleteCLM(
    $input: DeleteCLMInput!
    $condition: ModelCLMConditionInput
  ) {
    deleteCLM(input: $input, condition: $condition) {
      id
      name
      status
      date
      createdAt
      user {
        id
        name
        lastName
        job
        rol
        company {
          id
          name
          is_deleted
          createdAt
          updatedAt
        }
        audiences {
          nextToken
        }
        is_deleted
        username
        createdAt
        updatedAt
        userCompanyId
      }
      company {
        id
        name
        is_deleted
        createdAt
        updatedAt
      }
      adiditionalAttributes
      matchedSubscribers
      recordCount
      pathFile
      is_deleted
      updatedAt
      cLMUserId
      cLMCompanyId
    }
  }
`;
export const createMetrics = /* GraphQL */ `
  mutation CreateMetrics(
    $input: CreateMetricsInput!
    $condition: ModelMetricsConditionInput
  ) {
    createMetrics(input: $input, condition: $condition) {
      id
      user
      action
      view
      createdAt
      updatedAt
    }
  }
`;
export const updateMetrics = /* GraphQL */ `
  mutation UpdateMetrics(
    $input: UpdateMetricsInput!
    $condition: ModelMetricsConditionInput
  ) {
    updateMetrics(input: $input, condition: $condition) {
      id
      user
      action
      view
      createdAt
      updatedAt
    }
  }
`;
export const deleteMetrics = /* GraphQL */ `
  mutation DeleteMetrics(
    $input: DeleteMetricsInput!
    $condition: ModelMetricsConditionInput
  ) {
    deleteMetrics(input: $input, condition: $condition) {
      id
      user
      action
      view
      createdAt
      updatedAt
    }
  }
`;
export const createUnloadAudiece = /* GraphQL */ `
  mutation CreateUnloadAudiece(
    $input: CreateUnloadAudieceInput!
    $condition: ModelUnloadAudieceConditionInput
  ) {
    createUnloadAudiece(input: $input, condition: $condition) {
      id
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      idStatement
      isFinished
      dateExport
      hourExport
      path
      createdAt
      updatedAt
      unloadAudieceAudienceId
    }
  }
`;
export const updateUnloadAudiece = /* GraphQL */ `
  mutation UpdateUnloadAudiece(
    $input: UpdateUnloadAudieceInput!
    $condition: ModelUnloadAudieceConditionInput
  ) {
    updateUnloadAudiece(input: $input, condition: $condition) {
      id
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      idStatement
      isFinished
      dateExport
      hourExport
      path
      createdAt
      updatedAt
      unloadAudieceAudienceId
    }
  }
`;
export const deleteUnloadAudiece = /* GraphQL */ `
  mutation DeleteUnloadAudiece(
    $input: DeleteUnloadAudieceInput!
    $condition: ModelUnloadAudieceConditionInput
  ) {
    deleteUnloadAudiece(input: $input, condition: $condition) {
      id
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      idStatement
      isFinished
      dateExport
      hourExport
      path
      createdAt
      updatedAt
      unloadAudieceAudienceId
    }
  }
`;
export const createAudienceExport = /* GraphQL */ `
  mutation CreateAudienceExport(
    $input: CreateAudienceExportInput!
    $condition: ModelAudienceExportConditionInput
  ) {
    createAudienceExport(input: $input, condition: $condition) {
      id
      audienceID
      exportID
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      export {
        id
        name
        dateStart
        dateEnd
        id_status
        status_redshift {
          id
          code
          description
        }
        status
        count
        audience {
          nextToken
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        destiny
        transfered
        is_deleted
        error
        description
        createdAt
        updatedAt
        exportUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAudienceExport = /* GraphQL */ `
  mutation UpdateAudienceExport(
    $input: UpdateAudienceExportInput!
    $condition: ModelAudienceExportConditionInput
  ) {
    updateAudienceExport(input: $input, condition: $condition) {
      id
      audienceID
      exportID
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      export {
        id
        name
        dateStart
        dateEnd
        id_status
        status_redshift {
          id
          code
          description
        }
        status
        count
        audience {
          nextToken
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        destiny
        transfered
        is_deleted
        error
        description
        createdAt
        updatedAt
        exportUserId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudienceExport = /* GraphQL */ `
  mutation DeleteAudienceExport(
    $input: DeleteAudienceExportInput!
    $condition: ModelAudienceExportConditionInput
  ) {
    deleteAudienceExport(input: $input, condition: $condition) {
      id
      audienceID
      exportID
      audience {
        id
        name
        filters {
          key
          type_filter
          value_filter
          type_behaviour
          description_filter
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        isPublic
        count
        exports {
          nextToken
        }
        clm {
          id
          name
          status
          date
          createdAt
          adiditionalAttributes
          matchedSubscribers
          recordCount
          pathFile
          is_deleted
          updatedAt
          cLMUserId
          cLMCompanyId
        }
        is_deleted
        error
        description
        createdAt
        updatedAt
        userAudiencesId
        audienceUserId
        audienceClmId
      }
      export {
        id
        name
        dateStart
        dateEnd
        id_status
        status_redshift {
          id
          code
          description
        }
        status
        count
        audience {
          nextToken
        }
        user {
          id
          name
          lastName
          job
          rol
          is_deleted
          username
          createdAt
          updatedAt
          userCompanyId
        }
        destiny
        transfered
        is_deleted
        error
        description
        createdAt
        updatedAt
        exportUserId
      }
      createdAt
      updatedAt
    }
  }
`;
