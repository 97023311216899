import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Row, Col, Space, TimePicker, InputNumber, Button, Tooltip, notification   } from 'antd';
import { SearchOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
const App = (
  {
    filters,
    setFilters,
    dateNavigationRange,
    setDateNavigationRange,
    hourNavigationRange,
    setHourNavigationRange,
  }
) => {
  const [selectedData, setSelectedData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [showLoadingData, setShowLoadingData] = useState(false);
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(35);

  const fetchFilterHour = async (filter=null) => {
    try {
    const response = await API.graphql({
      query: `query MyQuery {
        getFiltersRedshift(input: { query: "getHours", name_filter: "${filter}"}){
          key
          description_filter
          type_filter
          value_filter
          id_statement
        }
      }`
    })
    const data = response.data.getFiltersRedshift;
    if (data[0].id_statement !== null) {
      await getValues({idStatement: response.data.getFiltersRedshift, query: "getHours", setter: setSelectedData, showLoading: setShowLoadingData});
    } else {
      const newData = data.map(item => {
        const { id_statement, ...rest } = item;
        return rest;
      });

      setSelectedData(newData.length > 0 && newData[0].value_filter !== "Sin Datos" ? newData : []);
      setShowLoadingData(false);
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    // Handle the error appropriately (e.g., show an error message)
  }
  }
  useEffect(() => {
    setShowLoadingData(true)
    fetchFilterHour(searchValue)
  }, [])


  const getValues = async ({idStatement, query, setter, showLoading}) => {
    let response = [];
    while (response.length === 0) {
      const result = await API.graphql({
        query: `query MyQuery {
          getData(input: {id_statement: "${idStatement}", query: "${query}" }) {
            key
            description_filter
            type_filter
            value_filter
          }
        }`
      });
      response = result.data.getData;
      if (response.length === 0) {
        await new Promise(resolve => setTimeout(resolve, 5000)); // Esperar 5 segundos antes de hacer la siguiente petición
      }
    }
    setter(response.length > 0 && response[0].value_filter !== "Sin Datos" ? response : [])
    showLoading(false)
  };

  const onChange = (value) => {
    //console.log(value)
    setStartDate(value);
  };

  const onEndChange = (value) => {
    //console.log(value)
    setEndDate(value);
  };

  const onChangeHour = (value, dateString) => {
    //console.log('Selected Time: ', value);
    //console.log('Formatted Selected Time: ', dateString);
    //console.log(dateString)
    setHourNavigationRange(dateString)
  };

  const onOk = (value) => {
    //console.log('onOk: ', value);
  };


const handleSetDateNavigationRange = () => {
  const dateRangeArray = [endDate.toString(), startDate.toString()];
  //console.log(dateRangeArray)
  setDateNavigationRange(dateRangeArray);
};

  return (
    <>
    <br/>
     <Row className="DemograficosColumns">
      <Col span={12}>
        <h3>Rango de Días
          <Tooltip placement="right" color="geekblue" title="Este rango de días es dinámico y siempre considerará el día cero como la fecha en la que se consulte la audiencia">
            <QuestionCircleTwoTone style={{ fontSize: '16px', marginLeft: '4px' }} />
          </Tooltip>
        </h3>
        <Space style={{ marginTop: '20px' }}> 
          {"Desde: "}<InputNumber  min={startDate} max={35} defaultValue={35} onChange={onEndChange}/> 
          {"Hasta: "}<InputNumber min={0} max={endDate} defaultValue={0} onChange={onChange} /> {" días anteriores a hoy"}
          <Button type="primary" shape="circle" icon={<SearchOutlined />} onClick={handleSetDateNavigationRange} style={{ fontSize: '20px' }} />
        </Space>
      </Col>
        <Col span={12}>
         <h3>Rango de Horas</h3>
          <Space direction="vertical" size={12}>
            <TimePicker.RangePicker format="H" onChange={onChangeHour} onOk={onOk}/>
          </Space>
        </Col>
      </Row>
    </>
  )
};
export default App;
