import { Card, Col, Row, Divider, Avatar, Typography, Spin } from "antd";
import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import { SubtitleDivider } from "../LineChart/ChartsDivider";
import DonutChart from "./DonutChart";
import * as custom_queries from "../../../../Custom_queries/queries"
const { Title, Text } = Typography;

const Donut = ({ genero, filters, data, showLoadingData, nameChart, onTop }) => {
  const [dataHombre, setDataHombre] = useState();
  const [dataMujer, setDataMujer] = useState();
  const [dataDesconocido, setDataDesconocido] = useState();
  const [porcentajeAudienceHombre, setPorcentajeAudienceHombre] = useState(['0'])
  const [porcentajeAudienceMujer, setPorcentajeAudienceMujer] = useState(['0'])
  const [porcentajeAudienceDesconocido, setPorcentajeAudienceDesconocido] = useState(['0'])

  useEffect(() => {
    if (data.total) {

      const maxSubscribers = Math.max(
        ...data.total
          .filter(item => item.value_filter.toLowerCase() !== "desconocido")
          .map(item => item.subscribers),
        0
      );
      
      const topValue = data.total.find(
        item =>
          item.subscribers === maxSubscribers &&
          item.value_filter.toLowerCase() !== "desconocido"
      );
      
      if (topValue) {
        onTop({ name: topValue.value_filter, value: topValue.subscribers.toLocaleString("en-US",{ notation: "compact", compactDisplay: "short"})});
      }

      const indices = Object.keys(data)
      let dataHombre = { name: "Genero", children: [] }
      let porcentajeHombre = []
      let dataMujer = { name: "Genero", children: [] }
      let porcentajeMujer = []
      let dataDesconocido = { name: "Genero", children: [] }
      let porcentajeDesconocido = []

const categorias = {
  Hombre: [dataHombre, porcentajeHombre],
  Mujer: [dataMujer, porcentajeMujer],
  Desconocido: [dataDesconocido, porcentajeDesconocido]
};

indices.forEach((item) => {
  if (data[item]) {
    let total = data[item].reduce((r, c) => r + c.subscribers, 0);

    // Se recorren los items para evaluar el tipo de audiencia, sus valores y porcentajes
    data[item].forEach((indicador) => {
      indicador.porcentaje = (total === 0) ? 0 : ((indicador.subscribers / total) * 100).toFixed(2) + "%"
      indicador.type = item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa"
    });

    const rows = new Set([...data[item].map(({ value_filter }) => value_filter)]);

    Array.from(rows).forEach((attribute) => {
      let children = data[item].filter(({ value_filter }) => value_filter === attribute);

      if (children.length > 0) {
        Object.entries(categorias).forEach(([categoria, [dataCategoria, porcentajeCategoria]]) => {
          switch (item) {
            case 'total':
              if (attribute === categoria) {
                dataCategoria.children = [{ ...children[0], children: [] }];
                if (porcentajeCategoria) {
                  porcentajeCategoria.push(children[0].porcentaje);
                }
              }
              break;
            case 'newAudience':
              if (dataCategoria.children && dataCategoria.children.length > 0 && attribute === categoria) {
                dataCategoria.children[0].children = [{ ...children[0], children: [] }];
                if (porcentajeCategoria) {
                  porcentajeCategoria.push(children[0].porcentaje);
                }
              }
              break;
            case 'dataExternalBD':
              if (dataCategoria.children && dataCategoria.children.length > 0 && attribute === categoria) {
                dataCategoria.children[0].children = [{ ...children[0], children: [] }];
                if (porcentajeCategoria) {
                  porcentajeCategoria.push(children[0].porcentaje);
                }
              }
              break;
            default:
              break;
          }
        });
      }
    });
  }
});

      setDataHombre(dataHombre)
      setPorcentajeAudienceHombre(porcentajeHombre)
      setDataMujer(dataMujer)
      setPorcentajeAudienceMujer(porcentajeMujer)
      setDataDesconocido(dataDesconocido)
      setPorcentajeAudienceDesconocido(porcentajeDesconocido)
    } else {
      setDataHombre({
        "name": "Genero",
        "children": [
          {
            "key": "1",
            "description_filter": "genero",
            "type_filter": 1,
            "value_filter": "Hombre",
            "subscribers": 0,
            "type": "Audiencia",
            "children": []
          }
        ]
      })
      setPorcentajeAudienceHombre(['0'])
      setDataMujer({
        "name": "Genero",
        "children": [
          {
            "key": "1",
            "description_filter": "genero",
            "type_filter": 1,
            "value_filter": "Mujer",
            "subscribers": 0,
            "type": "Audiencia",
            "children": []
          }
        ]
      })
      setPorcentajeAudienceMujer(['0'])
      setDataDesconocido({
        "name": "Genero",
        "children": [
          {
            "key": "1",
            "description_filter": "genero",
            "type_filter": 1,
            "value_filter": "Desconocido",
            "subscribers": 0,
            "type": "Audiencia",
            "children": []
          }
        ]
      })
      setPorcentajeAudienceDesconocido(['0'])

    }

  }, [data])

  return (
    <>
      <SubtitleDivider subtitle={nameChart} />
      <Row
        align="middle"
        justify="space-between"
        style={{
          backgroundColor: "white",
          marginLeft: 0,
          marginRight: 60,
          padding: 10,
        }}
        gutter={5}
      >
        <Col
          span={7}
          push={2}
          className="TextCharts"
          style={{ color: "#181D2E" }}
        >
          <Spin spinning={showLoadingData}>
            {data.total && (
              <DonutChart
                genero={genero}
                filters={filters}
                data={dataHombre}
                banderaGenero={'hombre'}
                porcentajeAudience={porcentajeAudienceHombre}
              />
            )}
          </Spin>

        </Col>

        <Col
          span={7}
          push={1}
          className="TextCharts"
          style={{ color: "#181D2E" }}
        >
          <Spin spinning={showLoadingData}>
            {data.total && (
              <DonutChart
                genero={genero}
                filters={filters}
                data={dataMujer}
                banderaGenero={'mujer'}
                porcentajeAudience={porcentajeAudienceMujer}
              />
            )}
          </Spin>

        </Col>
        <Col
          span={7}
          push={0}
          className="TextCharts"
          style={{ color: "#181D2E" }}
        >
          <Spin spinning={showLoadingData}>
            {data.total && (
              <DonutChart
                genero={genero}
                filters={filters}
                data={dataDesconocido}
                banderaGenero={'desconocido'}
                porcentajeAudience={porcentajeAudienceDesconocido}
              />
            )}
          </Spin>

        </Col>
      </Row>
    </>
  );
};

export default Donut;
