import { useState, useEffect, useContext } from "react";
import { Row, Col, Dropdown, Menu, Modal, Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons";
import { Auth, Hub } from "aws-amplify";
import { v4 as uuidv4 } from 'uuid'
import { UserContext } from '../../../GestorContext'
import { API } from "aws-amplify";

import "./UserCard.css";

const UserCard = () => {
  const [userName, setUserName] = useState();
  const [menuList, setMenuList] = useState();
  const [user, setUser] = useState();
  const [showChangePassword, setShowChangePassword] = useState();
  const [form] = Form.useForm();
  const userContext = useContext(UserContext)

  const navigate = useNavigate();

  const validate = async () => {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          return navigate("/");
        case "signOut":
          return navigate("/");
      }
    });
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        setUser(user);
        const { username } = user;
        setUserName(username);
        const groups = user.signInUserSession.idToken.payload["cognito:groups"];
        const items = [];
        groups.forEach((item) => {
          item === "Admin" &&
            items.push({
              label: "Administración de usuarios",
              key: "administrator",
            });
        });
        groups.forEach((item) => {
          item === "Admin" &&
            items.push({
              label: "Bitácora de usuarios",
              key: "log-administrator",
            });
        });
        items.push({
          label: "Cambiar contraseña",
          key: "change-password",
        });
        items.push({
          label: "Cerrar sesión",
          key: "logout",
        });
        setMenuList(items);
      }
    } catch (err) {
      //console.log("Error in user", err);
      navigate("/login");
    }
  };
  useEffect(() => {
    validate();
  }, []);

  const createLog = async (values) => {
    const usuario = await API.graphql({
     query: `mutation CreateMetrics( $input: CreateMetricsInput!){
                createMetrics(input:$input) {
                    id
                }
            }`,
        variables: {
            input : values
          },
        });
  };

  async function changePassword() {
    try {
      const values = await form.validateFields();
      const response = await Auth.changePassword(
        user,
        values.oldPassword,
        values.newPassword
      );
      createLog({
        action:"Cambio de contraseña",
        id: uuidv4(),
        user:userContext.username,
        view:"Cambiar contraseña",
   })
      setShowChangePassword(false);
    } catch (err) {
      //console.log(err);
    }
  }

  const onClickMenu = ({ key }) => {
    switch (key) {
      case "administrator":
        navigate("/users");
        break;
      case "log-administrator":
        navigate("/logusers");
        break;
      case "logout":
        Auth.signOut();
        break;
      case "change-password":
        setShowChangePassword(true);
        break;
      default:
        break;
    }
  };

  return (
    <Row
      style={{ width: 150, height: 50 }}
      justify="space-between"
      align="middle"
      className="RowUser"
    >
      <Col span={2} push={1}>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24 12.2744C24 15.8905 22.4006 19.1329 19.8706 21.333C17.7637 23.1652 15.0114 24.2744 12 24.2744C8.98862 24.2744 6.23633 23.1652 4.1294 21.333C1.59942 19.1329 0 15.8905 0 12.2744C0 5.647 5.37258 0.274414 12 0.274414C18.6274 0.274414 24 5.647 24 12.2744ZM12 20.6744C14.108 20.6744 16.0346 19.8979 17.5094 18.6154C17.6792 18.4678 17.843 18.3134 18.0004 18.1528C16.7033 16.0548 14.5 14.6744 12 14.6744C9.50009 14.6744 7.29672 16.0548 5.99963 18.1528C6.15703 18.3134 6.32083 18.4678 6.49061 18.6154C7.96546 19.8979 9.89206 20.6744 12 20.6744ZM12 12.2744C13.9882 12.2744 15.6 10.6626 15.6 8.67441C15.6 6.68619 13.9882 5.07441 12 5.07441C10.0118 5.07441 8.4 6.68619 8.4 8.67441C8.4 10.6626 10.0118 12.2744 12 12.2744Z"
            fill="#7084B1"
          />
        </svg>
      </Col>
      <Col span={16} pull={1}>
        <Dropdown.Button
          overlay={menuList && <Menu onClick={onClickMenu} items={menuList} />}
          icon={<DownOutlined />}
          type="text"
          trigger="click"
          className="SubtituloRegularGA"
        >
          {userName}
        </Dropdown.Button>
      </Col>
      <Modal
        clasName="ModalGA"
        title="Cambiar contraseña"
        maskClosable= "false"
        open={showChangePassword}
        onCancel={() => setShowChangePassword(false)}
        footer={[
          <Button key="back" onClick={() => setShowChangePassword(false)}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={changePassword}>
            Guardar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="form-user"
          onFinish={changePassword}
          initialValues={{ remember: false }}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Contraseña actual"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "Constraseña actual",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Nueva contraseña"
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Ingrese contraseña",
              },
              {
                min: 10,
                message: "La contraseña debe tener al menos 10 caracteres",
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: "La contraseña debe contener al menos una letra minúscula",
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: "La contraseña debe contener al menos una letra mayúscula",
              },
              {
                pattern: /^(?=.*[\W_])/,
                message: "La contraseña debe contener al menos un carácter especial",
              },
              {
                pattern: /^(?=.*[0-9])(?!.*(012|123|234|345|456|567|678|789|890))/,
                message: "La contraseña debe contener al menos un valor numérico y no debe tener patrones consecutivos",
              },
              {
                pattern: /^(?!.*(Telcel|Mientras|Temporal|Soporte))/i,
                message: "La contraseña no debe incluir palabras comunes o genéricas",
              },
              {
                pattern: /^(?!.*(.)\1\1)/,
                message: "La contraseña no debe contener patrones repetitivos de tres caracteres consecutivos",
              },
              {
                pattern: /^(?!.*([a-zA-Z])\1{2})/,
                message: "La contraseña no debe contener tres letras consecutivas iguales",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Repetir contraseña"
            name="validation"
            rules={[
              {
                required: true,
                message: "Repetir contraseña",
              },
              {
                min: 10,
                message: "La contraseña debe tener al menos 10 caracteres",
              },
              {
                pattern: /^(?=.*[a-z])/,
                message: "La contraseña debe contener al menos una letra minúscula",
              },
              {
                pattern: /^(?=.*[A-Z])/,
                message: "La contraseña debe contener al menos una letra mayúscula",
              },
              {
                pattern: /^(?=.*[\W_])/,
                message: "La contraseña debe contener al menos un carácter especial",
              },
              {
                pattern: /^(?=.*[0-9])(?!.*(012|123|234|345|456|567|678|789|890))/,
                message: "La contraseña debe contener al menos un valor numérico y no debe tener patrones consecutivos",
              },
              {
                pattern: /^(?!.*(Telcel|Mientras|Temporal|Soporte))/i,
                message: "La contraseña no debe incluir palabras comunes o genéricas",
              },
              {
                pattern: /^(?!.*(.)\1\1)/,
                message: "La contraseña no debe contener patrones repetitivos de tres caracteres consecutivos",
              },
            ]}
          >
            <Input.Password onFinish={changePassword} />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
};

export default UserCard;
