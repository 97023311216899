import ReactDOM from 'react-dom/client'
import App from './App'
/*import 'antd/dist/antd.css';*/
/*import * as serviceWorker from './serviceWorker';*/
/*import { ThemeProvider } from 'emotion-theming'*/
/*import { theme } from 'styles'*/

const root = ReactDOM.createRoot(
    document.getElementById('root')
)

root.render(
    <App />
)
