import { useState, useEffect } from 'react'
import {
    Layout,
    Row,
    Col,
    Divider,
    Image,
    message
} from 'antd';
import { API } from "aws-amplify";
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Hub, Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'
import { UserContext } from '../../GestorContext'

import HeaderMenu from './HeaderMenu'
import UserCard from './UserCard/UserCard'
import './AppFrame.css'
//import { createLogger } from 'fork-ts-checker-webpack-plugin/lib/logger/LoggerFactory';


const { Header, Content } = Layout;



const styleContent = {
    paddingTop: 50.55,
    height: '100%',
    width: '100%',
    position: 'absolute'
}

const styleContainer = {
    padding: 55,
    height: '100%',
    /* borderColor: 'red',
    borderStyle: 'solid',
    borderWidth: 5, */
}
const AppFrame = ({ children }) => {

    const [user, setUser] = useState()
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const createLog = async (values) => {
        const usuario = await API.graphql({
            query: `mutation CreateMetrics( $input: CreateMetricsInput!){
                    createMetrics(input:$input) {
                        id
                    }
                }`,
            variables: {
                input: values
            },
        });
    };

    const getUserInfo = () => {
        Auth.currentAuthenticatedUser()
            .then(data => setUser(data))
            .catch(err => {
                //console.log("Error con sesion de usuario", err)
                navigate('/login')
            })
    }

    useEffect(() => {
        if (pathname.split('/')[1] !== 'login' && pathname !== '/' && user) {
            const { signInUserSession } = user
            const { idToken } = signInUserSession
            const { payload } = idToken
            switch (pathname.split('/')[1]) {
                case 'export':
                    if (!payload['cognito:groups'].includes('Admin') && !payload['cognito:groups'].includes('Company')) navigate('/audience')
                    break;
                case 'exports':
                    if (!payload['cognito:groups'].includes('Admin') && !payload['cognito:groups'].includes('Company')) navigate('/audience')
                    break;
                case 'users':
                    if (!payload['cognito:groups'].includes('Admin')) navigate('/audience')
                    break;
                case 'user':
                    if (!payload['cognito:groups'].includes('Admin')) navigate('/audience')
                    break;
                default:
                    break;
            }
        }
    }, [pathname, user])

    useEffect(() => {
        const validate = async () => {
            Hub.listen('auth', (data) => {
                const { payload } = data
                switch (payload.data.message) {
                    case "Incorrect username or password.":
                        message.error('Usuario o contraseña incorrecta.')
                        break
                    case "User is disabled.":
                        message.error('Usuario bloqueado, consulta al administrador.')
                        break
                    case "User does not exist.":
                        message.error('Usuario inexistente')
                        break
                    default:
                        break
                }
                switch (payload.event) {
                    case 'signIn':
                        getUserInfo()
                        createLog({
                            action: "Inicio de sesión",
                            id: uuidv4(),
                            user: payload.data.username,
                            view: "Login",
                        })
                        navigate('/')
                        break
                    case 'signOut':
                        setUser()
                        createLog({
                            action: "Sesión cerrada",
                            id: uuidv4(),
                            user: payload.data.username,
                            view: "Logout",
                        })
                        navigate('/login')
                        break
                    case 'tokenRefresh':
                        break
                    case 'signIn_failure':
                        break
                    default:
                        //console.log("Evento inesperado", data)
                        setUser()
                        navigate('/login')
                        break
                }
            })
            getUserInfo()
        }
        validate()
    }, [])

    return (
        <UserContext.Provider value={user}>
            <Layout className="HeaderGA">
                {user && <Header
                    style={{
                        backgroundColor: 'var(--background1)',
                        position: 'fixed',
                        zIndex: 1000,
                        width: '100%'
                    }}
                >
                    <Row align='middle' justify="center">
                        <Col span={3}>
                            <Link to='/audience'>
                                <Image
                                    src="/images/header/2560px-Telcel_logo 2.png"
                                    preview={false}
                                />
                            </Link>
                        </Col>
                        <Col span={1}>
                            <Divider className="DividerHeaderGA" type="vertical" />
                        </Col>
                        <Col span={5} className="TituloBoldGA" style={{ userSelect: 'none' }}>
                            GESTOR DE AUDIENCIAS
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={1}>
                                </Col>
                                <Col span={22}>
                                    <HeaderMenu pathname={pathname} />
                                </Col>
                                <Col span={1}>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={3}>
                            <UserCard />
                        </Col>
                    </Row>
                </Header>}
                <Content className="site-layout" style={styleContent}>
                    <div className="site-layout-background" style={styleContainer}>
                        {children}
                    </div>
                </Content>
            </Layout>
        </UserContext.Provider>
    )
}

export default AppFrame
