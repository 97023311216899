import { useEffect, useState, useRef } from 'react'

import {
  Col,
  Row,
  Space,
  Button,
  Table,
  Input,
} from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import './ListUsers.css'
import { API } from 'aws-amplify';

const { Search } = Input;

const LogUsers = props => {
  const [data, setData] = useState()
  const [grid, setGrid] = useState()
  const [typeGrid, setTypeGrid] = useState('list')
  const [dataUsers, setDataUsers] = useState()
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
    
      const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
      };
    
      const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
      };

      const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }) => (
          <div
            style={{
              padding: 8
            }}
          >
            <Input
              ref={searchInput}
              placeholder={`Buscar`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{
                marginBottom: 8,
                display: "block"
              }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                  width: 90,
                  borderRadius:"10px",
                  backgroundColor: "#391EDE"
                }}
              >
                Buscar
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                  width: 90,
                  borderRadius:"10px"
                }}
              >
                Limpiar
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({
                    closeDropdown: false
                  });
                  setSearchText(selectedKeys[0]);
                  setSearchedColumn(dataIndex);
                }}
              >
                Filtrar
              </Button>
            </Space>
          </div>
        ),
        filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? "#1890ff" : undefined
            }}
          />
        ),
        onFilter: (value, record) =>
          record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          }
        },
        render: (text) =>
          searchedColumn === dataIndex ? (
          text  
          ) : (
            text
          )
      });

      const getData = async () => {
        let nextToken = null;
        let allMetrics = [];
        do {
          const allTodos = await API.graphql({
            query: `
              query ListMetrics(
                $limit: Int
                $nextToken: String
              ){
                listMetrics(limit: $limit, nextToken: $nextToken){
                  items {
                    action
                    createdAt
                    id
                    updatedAt
                    user
                    view
                  }
                  nextToken
                }
              }
            `,
            variables: {
              limit: 2000,
              nextToken,
            },
          });
          const items = allTodos.data.listMetrics.items;
          allMetrics = allMetrics.concat(items);
          nextToken = allTodos.data.listMetrics.nextToken;
        } while (nextToken !== null);
        setDataUsers(allMetrics);
      };
      

  useEffect(() => {
    getData();
  }, []);



  useEffect(() => {
      const columns = [
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Usuario
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,

          dataIndex: 'user',
          key: 'id',
          sorter: (a, b) => {
            return a.user.localeCompare(b.user);
          },
          showSorterTooltip: false,
          ...getColumnSearchProps("user")
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Fecha de Cambio
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'updatedAt',
          ...getColumnSearchProps('updatedAt'),
          render: (text) => text.substring(0, 10),
          defaultSortOrder: 'descend',
          sorter: {
            compare: (a, b) =>
              Date.parse(a.updatedAt) - Date.parse(b.updatedAt),
          },
          showSorterTooltip: false,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Acción
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'action',
          ...getColumnSearchProps('action'),
          
          sorter: (a, b) => {
            return a.action.localeCompare(b.action);
          }, 
          showSorterTooltip: false,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Vista
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'view',
          sorter: (a, b) => {
            return a.view.localeCompare(b.view);
          },
          showSorterTooltip: false,
        },
      ]
      setGrid(<Table size="small" columns={columns} dataSource={data} sortOrder={'descend'} style={{ width: '100%' }} />)
    }, [data, typeGrid])


  useEffect(() => {
    setData(dataUsers);
  }, [dataUsers])


  return (
    <div className='ListUsersComponent'>
      <Row className='MenuListUsers' align="middle">
        <Col span={6}
          className='TituloBoldGA Col1MenuExports'
          style={{ textTranform: 'uppercase' }}
        >
          Bitácora de usuarios
        </Col>
        <Col span={13} className='SubtituloRegularGA'>
        </Col>
      </Row>
      {grid && <Row>{grid}</Row>}
    </div>
  )
}


export default LogUsers
