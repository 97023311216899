import { useEffect, useState, useContext } from "react";
import {
  Input,
  Button,
  Form,
  DatePicker,
  Row,
  Col,
  Table,
  Modal,
  Radio,
  Typography,
  Spin,
  message
} from "antd";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { UserContext } from "../../../GestorContext";

import ListAudiences from "./ListAudiences/";
import "./CreateExport.css";

const pako = require('pako');
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Text } = Typography;
var today = new Date();
var dd = String(today.getDate()).padStart(2, "0");
var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy = today.getFullYear();
today = yyyy + "/" + mm + "/" + dd;

const CustomizedForm = ({
  onChange,
  fields,
  onFinish,
  onFinishFailed,
  showAudiences,
  onChangeButtom,
  mode,
}) => (
  <Form
    className="FormExports"
    name="basic"
    fields={fields}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    onFieldsChange={(_, allFields) => {
      onChange(allFields);
    }}
  >
    <div style={{ display: !showAudiences ? "block" : "none" }}>
      {mode === "upd" ? (
        <h2 className="TituloBoldGA">Actualizando la exportación</h2>
      ) : mode === "dup" ? (
        <h2 className="TituloBoldGA">Duplicando la exportación</h2>
      ) : (
        <h2 className="TituloBoldGA">Creando nueva exportación</h2>
      )}
      <Form.Item
        className="SubmenuRegularGA"
        name="nameExport"
        rules={[
          {
            required: true,
            message: "Es requerido el nombre de la Exportación",
          },
          { validator: async (_, value) => {
            const validador = await API.graphql({
              query: `
              query ListExports(
                $filter: ModelExportFilterInput
                $limit: Int
                $nextToken: String
              ) {
                listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
                  items {
                    id
                    name
                  }}}`,
              variables: { filter: { name: { eq: value } } },
            });
            if (validador.data.listExports.items.length>0 && mode !== "upd") {
              throw new Error('El nombre de la exportación ya está en uso');
            }
          },
        }
        ]}
      >
        <Input placeholder="Nombre de la exportación" disabled={mode === "upd" ? true : false} />
      </Form.Item>
      <Form.Item
        name="descriptionExport"
        rules={[
          {
            required: false,
            message: "Es requerida una descripción para la exportación",
          },
        ]}
      >
        <TextArea placeholder="Descripción de la exportación" />
      </Form.Item>
      <Form.Item
        name="export-period"
        rules={[
          {
            type: "array",
            required: false,
            message: "Ingresa el periodo de la exportación",
          },
        ]}
        initialValue={[
          moment(today, "YYYY-MM-DD HH:MM:SS"),
          moment(today, "YYYY-MM-DD HH:MM:SS"),
        ]}
      >
        <RangePicker
          /* open */
          disabledDate={(current) => current < moment(today, "YYYY-MM-DD HH:MM:SS")}
          allowClear={false}
          placement="bottomLeft"
          showToday
        />
      </Form.Item>
      <Form.Item
        name="export_line"
      >
      <Text className="SubtituloBoldGA" style={{ fontSize: 15}}>Destino:  </Text>
        <Radio.Group optionType="button" buttonStyle="solid" onChange={onChangeButtom} defaultValue={1}>
          <Radio.Button style={{borderRadius:"10px"}} value={1}> INTERNO</Radio.Button>  
          <Radio.Button style={{borderRadius:"10px"}} value={2}> EXTERNO</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Siguiente
        </Button>
      </Form.Item>
    </div>
  </Form>
);

const CreateExport = () => {
  const [dataAudience, setDataAudience] = useState();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get("mode");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState();
  const [audiences, setAudiences] = useState([]);
  const [showAudiences, setShowAudiences] = useState(false);
  const [fields, setFields] = useState([]);
  const [showLoadingData, setShowLoadingData] = useState(false);

  const [exportForm, setExportForm] = useState({
    id: null,
    name: null,
    description: null,
    dateStart: null,
    dateEnd: null,
    audiences: [],
  });

  const [exportUpdate, setExportUpdate] = useState({
    id: null,
    name: null,
    description: null,
    dateStart: null,
    dateEnd: null,
    audiences: [],
  });

  const userContext = useContext(UserContext);

  const parms = useParams();
  const navigate = useNavigate();

  const saveExport = async (exportItem, audiences) => {
    let newExport = { ...exportItem };
    if (audiences.length===0) message.error('No hay audiencias agregadas');
    if (audiences.length===0) setShowLoadingData(false)
    if (audiences.length===0) return;
    newExport["audiences"] = audiences.map((item) => item.id);
    //console.log(newExport);  
    const exportacion = await API.graphql({
      query: `
            mutation CreateExportLambda($input: createExportInput) {
                createExportLambda(input: $input) {
                  id
                  name
                }
              }
            `,
      variables: { input: newExport },
    });
    if (exportacion) navigate("/exports");
  };

  useEffect(() => {
    const fetchExport = async () => {
      let nextToken = null;
      let allAudiences = [];
    
      do {
        const response = await API.graphql({
          query: `
                  query GetExport($id: ID!, $nextToken: String) {
                      getExport(id: $id) {
                      createdAt
                      dateEnd
                      dateStart
                      destiny
                      name
                      description
                      audience(nextToken: $nextToken) {
                          items {
                            audience {
                              id
                              name
                              createdAt
                            }
                          }
                          nextToken
                        }
                      }
                  }`,
          variables: { id: parms.id, nextToken },
        });
    
        if (response.data.getExport) {
          setData(response.data.getExport);
          const audiences = response.data.getExport.audience.items.map((item) => item.audience);
          allAudiences = [...allAudiences, ...audiences];
          nextToken = response.data.getExport.audience.nextToken;
        } else {
          nextToken = null;
        }
      } while (nextToken);
    
      setAudiences(allAudiences);
    };
    
    const fetchAudience = async () => {
      const response = await API.graphql({
        query: `
                query GetAudience($id: ID!) {
                    getAudience(id: $id) {
                    id
                    name
                    createdAt
                    count
                    }
                }`,
        variables: { id: parms.id },
      });
      if (response.data.getAudience) {
        setAudiences([response.data.getAudience]);
      }
    };

    if (parms.id) {
      fetchExport();
      fetchAudience();
    }
  }, []);

  useEffect(() => {
    if (data) {
      setFields([
        {
          name: ["nameExport"],
          value: data.name,
        },
        {
          name: ["descriptionExport"],
          value: data.description,
        }
      ]);
    }
  }, [data]);

  const handleOk = () => {
    setShowAudiences(true);
    setIsModalOpen(false);
  };



  const updateExport = async (exportupdate, audiences) => {
    let newExport = { ...exportupdate };
    if (audiences.length===0) message.error('No hay audiencias agregadas');
    if (audiences.length===0) setShowLoadingData(false)
    if (audiences.length===0) return;
    if (!audiences) return;
    newExport["audiences"] = audiences.map((item) => item.id);
    const usuario = await API.graphql( graphqlOperation(`
        mutation updateExportLambda(
        $input: updateExportInput!
        ) {
        updateExportLambda(input: $input) {
            id
        }
    }` , 
         {
            input: newExport
    }));
    if (usuario) navigate("/exports");
  };

  const onFinish = (values) => {
    setShowAudiences(true);
    const rangeValue = values["export-period"];
    const nameExport = values["nameExport"];
    const descriptionExport = values["descriptionExport"];
    switch (mode){
        case "upd":
            setExportUpdate(
                {
                id: parms.id,
                name: nameExport,
                description: descriptionExport,
                dateStart: rangeValue[0].format("YYYY/MM/DD"),
                dateEnd: rangeValue[1].format("YYYY/MM/DD"),
                destiny: destino,
                audiences: []}
                );
            break;
        default:
            setExportForm({
            //id: parms.id && parms.id,
            name: nameExport,
            user: userContext.attributes.sub,
            description: descriptionExport,
            dateStart: rangeValue[0].format("YYYY/MM/DD"),
            dateEnd: rangeValue[1].format("YYYY/MM/DD"),
            destiny: destino,
            audiences: [],
            });
    }
  };

  const onFinishFailed = (errorInfo) => {
    //setNewExports(false)
  };

  const getData = async (input) => {
    const allTodos = await API.graphql({
      query: `
            query ListAudiences(
                $filter: ModelAudienceFilterInput
                $limit: Int
                $nextToken: String
              ) {
                listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
                  items {
                    id
                    name
                    createdAt
                    count
                  }
                  nextToken
                }
              }
            `,
      variables: { is_deleted: { eq: false } },
    });
    //const nombreDeAudiencia = API.graphql({ query: mutations.createAudience { name } });
      const items = allTodos.data.listAudiences.items;
      // Filtrar y eliminar los objetos nulos
      setDataAudience(items)
  };

  useEffect(() => {
    const filters = { filter: { name: { contains: "" } } };
    getData(filters);
  }, []);

  const [destino, setDestino ] = useState(1)
  const onChangeButtom = (e) => {
    setDestino(e.target.value)
  };

  return (
    <>
      <Spin spinning={showLoadingData}>
      <>
        <CustomizedForm
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          onChangeButtom={onChangeButtom}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          showAudiences={showAudiences}
          mode={mode}
        />
      </>
      
      <div
        style={{ display: showAudiences ? "block" : "none" , marginTop:'-150px'}}
      >
        <Row
        style={{
          backgroundColor: "white",
          marginLeft: 150,
          marginRight: 0,
          padding: "10px 30px",
        }}
      >
        <Button
          className="SubtituloRegularGA Button1"
          type="primary"
          onClick={() => setIsModalOpen(true)}
        >
          + Agregar audiencias
        </Button>
        <Modal
          title="Seleccionar audiencias"
          className="SeleccionarAudienciasEnExport"
          open={isModalOpen}
          footer={[
            <Button key="back" onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Aceptar
            </Button>,
          ]}
          width={1300}
        >

          <ListAudiences audiences={audiences} setAudiences={setAudiences} />
        </Modal>
        <br/>
        <Col span={24}>
        <Table
          style={{ width: 1300 , marginTop:"30px"}}
          /* rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectTable
                    }} */
          columns={[
            {
              title: "Nombre",
              dataIndex: "name",
              onFilter: (value, record) => record.name.indexOf(value) === 0,
            },
            {
              title: "Fecha Creación",
              dataIndex: "createdAt",
              render: (text, record, index) => {
                if (text) {
                  return text.substring(0, 10);
                }
                return null; // o cualquier otro valor predeterminado que desees mostrar cuando el texto sea null o undefined
              },
            },
            {
              title: "Subscriptores",
              dataIndex: "count",
            },
          ]}
          dataSource={audiences}
          pagination={false}
          rowKey="id"
          scroll={{ y: 290 }}
          loading={showLoadingData}
        />
        </Col>
        <Row
        style={{
         marginTop:"20px"
        }}
      >
        <Col span={22}>
        <Button
          className="SubtituloRegularGA Button1"
          type="primary"
          onClick={() => setShowAudiences(!showAudiences)}
        >
          Anterior
        </Button>
        </Col>
        <Col span={2}>
        <Button
          className="SubtituloRegularGA Button1"
          type="primary"
          onClick={() => {
            setShowLoadingData(true)
            {mode === "upd" ? 
               updateExport(exportUpdate, audiences)
             : saveExport(exportForm, audiences)
            }
          }}
        >
          + Inicio de la Exportación
        </Button>
        </Col>

        </Row>
        </Row>
      </div>
      </Spin>
    </>
  );
};

export default CreateExport;
