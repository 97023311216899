import {
    useEffect,
    useRef
} from "react"
import * as d3 from "d3"
import { formatNumber } from '../../../../Helpers/Helpers'


const Legend = props => {

    const maxValue = props.subscribers 

    const { minColor, maxColor } = props
    const width = 250
    const height = 30
    const d3Container = useRef(null)


    useEffect(
        () => {
            const svg = d3.select(d3Container.current)
            let fakeData = []
            let legend_width = 220
            let rectWidth = 2
            let mleft = 10
            for (var i = 0; i < legend_width; i += rectWidth) {
                fakeData.push(i)
            }
            let valuesA = [0, 0.25, 0.5, 0.75, 1]
            const scaleValues = d3.scaleLinear()
                .domain([0, 1])
                .range([0, maxValue])

            const scaleValues2 = d3.scaleLinear()
                .domain([0, maxValue])
                .range([0, 1])

            let valuesB = valuesA.map(d => scaleValues(d))

            const scaleValues3 = d3.scaleLinear()
                .domain([0, legend_width])
                .range([0, 1])

            svg.select('.bars')
                .selectAll('rect')
                .data(fakeData)
                .join("rect")
                .attr("x", function (d) { return d + mleft; })
                .attr("y", 10)
                .attr("height", 6)
                .attr("width", rectWidth)
                .attr("fill", (d, i) => {
                    return d3.interpolateHsl(minColor, maxColor)(scaleValues3(d))
                })

            svg.select('.values')
                .selectAll('text')
                .data(valuesB)
                .join("text")
                .attr('text-anchor', 'middle')
                .attr('font-size', '10px')
                .attr('fill', '#1D1B1B')
                .attr("x", (d, i) => legend_width * (i / (valuesB.length - 1)) + mleft)
                .attr("y", 30)

                .text((d, i) => formatNumber(d))

            svg.select('.ticks').selectAll('line')
                .data(valuesB)
                .join("line")


                .attr("x1", (d, i) => legend_width * (i / (valuesB.length - 1)) + mleft)
                .attr("x2", (d, i) => legend_width * (i / (valuesB.length - 1)) + mleft)
                .attr("y1", 8)
                .attr("y2", 20)
                .attr('stroke', '#1D1B1B')
                .attr('stroke-width', 2)
                .attr('stroke-opacity', 0.9)


            svg.select('.currValue').selectAll('text')
                .data(valuesB)
                .join("text")
                .attr('text-anchor', 'middle')
                .attr('font-size', '10px')
                .attr("x", (d, i) => legend_width * (i / (valuesB.length - 1)) + mleft)
                .attr("y", 30)

        },
        [minColor, maxColor, maxValue]//props.currValue, props.maxValue, props.color, props.height, props.width]
    );

    return (
        <div style={{}}>
            <svg
                width={width}
                height={height}
                ref={d3Container} >
                <g className="bars" ></g>
                <g className="values"></g>
                <g className="ticks"></g>
                <rect className="currValue"></rect>
            </svg>
        </div>
    )

};

export default Legend;