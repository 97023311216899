import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import AppFrame from './components/AppFrame'
import Router from './Navigation/Router'
import AudiencePage from './Navigation/AudiencePage'
import Exports from './Navigation/ExportsPage'
import AudiencesPage from './Navigation/AudiencesPage'
import NotFoundPage from './Navigation/NotFoundPage'
import CreateExportPage from './Navigation/CreateExportPage'
import LogPage from './Navigation/LogUsers'
import LoginPage from './Navigation/LoginPage'
import ListUsers from './Navigation/ListUsers'
import UserPage from './Navigation/UserPage'
import CreateAudiencesPage from './Navigation/CreateAudiencesPage'
import ListUserGroups from './Navigation/ListGroups'
import awsconfig from './aws-exports'
import '@aws-amplify/ui-react/styles.css'
import 'antd/dist/antd.css'
import './App.css'
import aws_custom_config from './aws-custom_config'

Amplify.configure(awsconfig,aws_custom_config)

const App = () => {
  return (
    <>
      <BrowserRouter>
        <AppFrame >
          <Routes>
            <Route path="/audience">
              <Route
                index
                element={<AudiencePage />}
              />
              <Route
                path=':id'
                element={<AudiencePage />}
              />
            </Route>
            <Route
              path="/audiences"
              element={<AudiencesPage />}
            />
            <Route
              path="/newaudience"
              element={<CreateAudiencesPage />}
            />
            <Route
              path="/exports"
              element={<Exports />}
            />
            <Route path="/export">
              <Route
                index
                element={<CreateExportPage />}
              />
              <Route
                path=':id'
                element={<CreateExportPage />}
              />
            </Route>
            <Route path="/user">
              <Route
                index
                element={<UserPage />}
              />
              <Route
                path=':id'
                element={<UserPage />}
              />
            </Route>
            <Route
              path="/users"
              element={<ListUsers />}
            />
            <Route
              path="/usersgroup"
              element={<ListUserGroups />}
            />
            <Route
                path="/logusers"
                element={<LogPage />}
              />
            <Route
              path="/login"
              element={<LoginPage />}
            />
            <Route
              path='/'
              element={<Router />}
            />
            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Routes>
        </AppFrame>
      </BrowserRouter>
    </>
  )
}

//export default withAuthenticator(App)
export default App
