import { useState, useEffect, useRef } from 'react';
import DeckGL from '@deck.gl/react'
import { GeoJsonLayer } from '@deck.gl/layers'
import { xor, maxBy, find, difference } from 'lodash'
import { scaleLinear, color, interpolateHsl } from 'd3'
import { API } from 'aws-amplify';
import HighLight from './HighLight';
import estates from './polygoons/mx/estates.geojson';
import * as custom_queries from "../../../Custom_queries/queries";

//Se comentan las líneas para validar que funcionene en klkos servidores de AWS
//import mapboxgl from "mapbox-gl" // This is a dependency of react-map-gl even if you didn't explicitly install it
//import Map from 'react-map-gl'
// Se agrega por bug al desplegar en tomcat
// eslint-disable-next-line import/no-webpack-loader-syntax
//mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default
//const tokenMapBox = process.env.REACT_APP_MAPBOX_TOKEN

const initialViewState = {
  longitude: -102,
  latitude: 23,
  zoom: 3.7,
  maxZoom: 20,
  minZoom: 3.7

}
/*
    Las coordenadas corresponden a las coordenasdas del centro de México.
    Al cambiar de país modificar los valroes, se pueden agregar en variables de entorno para las integraciones futuras
    */
const LONGITUDE_RANGE = [-123, -85]
const LATITUDE_RANGE = [15, 35]

const BaseMap = ({
  ubicaciones,
  setUbicaciones,
  filters,
  minColor,
  maxColor,
  typeLocation,
  setMaxCountLocations,
  data, onTop
}) => {
  //const [data, setData] = useState()
  const [hoverInfo, setHoverInfo] = useState()
  const [viewState, setViewState] = useState()
  const [layer, setLayer] = useState()
  const [locations, setLocations] = useState([])
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    const diff1 = difference(ubicaciones.map(item => item.key), locations)
    const diff2 = difference(locations, ubicaciones.map(item => item.key))
    if (diff1.length > 0 || diff2.length > 0) {
      setLocations(ubicaciones.map(item => item.key))
    }
  }, [ubicaciones])

  useEffect(() => {
    if (data.total) {

      const maxSubscribers = Math.max(
        ...data.total
          .filter(item => item.value_filter.toLowerCase() !== "desconocido")
          .map(item => item.subscribers),
        0
      );
      const topValue = data.total.find(
        item =>
          item.subscribers === maxSubscribers &&
          item.value_filter.toLowerCase() !== "desconocido"
      );
      if (topValue) {
        onTop({ name: topValue.value_filter, value: topValue.subscribers.toLocaleString("en-US",{ notation: "compact", compactDisplay: "short"})});
      }

      let dataMap = data.dataExternalBD ? data.dataExternalBD : data.newAudience ? data.newAudience : data.total
      if (data.total !== 0) {
        const maxCount = (maxBy(dataMap, 'subscribers')?.subscribers ?? 0);
        setMaxCountLocations(maxCount)
        const scaleColor = scaleLinear()
          .domain(
            [0, maxCount]
          ).range([0, 1])
        setLayer(new GeoJsonLayer({
          key: 'geojson-layer',
          data: estates,
          pickable: true,
          stroked: true,
          filled: true,
          autoHighlight: true,
          highlightColor: [65, 105, 225, 130],
          lineWidthUnits: 'pixels',
          getLineWidth: (d) => locations.find(i => i === d.properties.CVEGEO) ? 4 : 1,
          lineWidthMaxPixels: 3,
          lineWidthMinPixels: .5,
          onHover: info => {
            if (info.object) {
              let estate = find(dataMap, { 'key': info.object.properties.CVEGEO})
              info.object.properties.value = estate ? estate.subscribers : 0
              info.object.properties.description_filter = estate ? estate.description_filter : ''
            }
            setHoverInfo(info)
          },
          getFillColor: ({ properties }) => {
            const { CVEGEO } = properties
            let estate = find(dataMap, { 'key': CVEGEO })
            let colorEstate = [65, 105, 225, 50]
            if (estate) {
              // Cambiar colores por useState si es que los colores se pueden elegir dinámicamente
              var colorRgb = color(interpolateHsl(minColor, maxColor)(scaleColor(estate.subscribers)))
            } else {
              var colorRgb = color(interpolateHsl(minColor, maxColor)(scaleColor(0)))
            }
            colorEstate = [colorRgb.r, colorRgb.g, colorRgb.b]
            return colorEstate
          },
          /* onClick: (info, event) => {
            const { object } = info
            setLocations(xor(locations, [object.properties.DPTO_CCDGO]))
            setViewState()
          }, */
          updateTriggers: {
            getFillColor: [locations, minColor, maxColor, dataMap],
            getLineWidth: locations
          }
        }))

        const auxData = []
        const diff1 = difference(ubicaciones.map(item => item.key), locations)
        const diff2 = difference(locations, ubicaciones.map(item => item.key))
        if (diff1.length > 0 || diff2.length > 0) {
          data.forEach(item => {
            if (locations.map(e => e).includes(item.key)) {
              if (ubicaciones.length !== 0 && diff2.length === 0) {
                ubicaciones.map(item2 => item2.key === item.key ?
                  auxData.push(
                    {
                      "key": item.key,
                      "description_filter": item2.description_filter,
                      "type_filter": item.type_filter,
                      "value_filter": item.value_filter,
                    }
                  )
                  :
                  null
                )
              } else {
                auxData.push(
                  {
                    "key": item.key,
                    "description_filter": item.description_filter,
                    "type_filter": item.type_filter,
                    "value_filter": item.value_filter,
                  }
                )
              }
            }
          }
          )
          setUbicaciones(auxData)
        }
      }
    }
  }, [locations, data, minColor, maxColor])
  return (
    <>

      <DeckGL
        style={{ backgroundColor: 'var(--variant2-blue)', display: 'block', height: '100%', width: '100%' }}
        initialViewState={initialViewState}
        viewState={viewState}
        controller={true}
        layers={layer && layer}
        onViewStateChange={({ viewState }) => {
          viewState.longitude = Math.min(LONGITUDE_RANGE[1], Math.max(LONGITUDE_RANGE[0], viewState.longitude,))
          viewState.latitude = Math.min(LATITUDE_RANGE[1], Math.max(LATITUDE_RANGE[0], viewState.latitude,))
        }}
      >
        {hoverInfo && (
          hoverInfo.object && <HighLight info={hoverInfo} />
        )}
      </DeckGL>
    </>
  )
}
export default BaseMap
