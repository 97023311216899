import { Row, Col, Table, message, Button } from "antd";
import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { ChartDivider } from "../Charts/LineChart/ChartsDivider";
import { Porcentaje } from "./PercentageTable";
import * as custom_queries from "../../../Custom_queries/queries"

const TableBehavoirs = ({
  data,
  filters,
  dataBaseExternal,
  showLoadingData,
  onTop
}) => {

  const [tableAudience, setTableAudience] = useState();
  const [tableNewAudience, setTableNewAudience] = useState();
  const [tableDataBaseExternal, setTableDataBaseExternal] = useState();
  const [dataNewAudience, setDataNewAudience] = useState([]);
  const [column, setColumn] = useState()

  useEffect(() => {
    if (data.total) {

      let topValue = null;

      if (data.total.length > 1) {
        for (let i = 0; i < data.total.length; i++) {
          const item = data.total[i];
          
          if (topValue === null && item.value_filter !== 'Desconocido') {
            topValue = item;
          } else if (topValue !== null && item.value_filter !== 'Desconocido' && item.subscribers > topValue.subscribers) {
            topValue = item;
          }
        }
      } else if (data.total.length === 1) {
        const item = data.total[0];
        if (item.value_filter === 'Desconocido') {
          topValue = item;
        } else {
          topValue = item;
        }
      }

      if (topValue !== null) {
        onTop({ name: topValue.value_filter, value: topValue.subscribers.toLocaleString("en-US", { notation: "compact", compactDisplay: "short" }) });
      }



      const indices = Object.keys(data)
      let dataChart = []

      let columns = indices.map((item, index) => {
        // Se evalua que existan datos en las diferentes audiencias
        if (data[item]) {
          // Se obtiene el total de los subscriptores
          let total = data[item].reduce((r, c) => r + c.subscribers, 0)
          // Se recorren los items para evaluar el tipo de audiencia y sus valores y porcentajes
          data[item].forEach(indicador => {
            indicador.porcentaje = (total === 0) ? 0 : ((indicador.subscribers / total) * 100).toFixed(2) + "%"
            indicador.type = item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa"
          })
          dataChart.push(...data[item])

          return ({
            title: item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa",
            dataIndex: `value`,
            key: `suscriptors`,
            align: "center",
            render(text, record) {
              return {
                props: {
                  className: "TextCharts",
                  style: { color: item === 'total' ? "#CC0000" : item === 'newAudience' ? "#FFA017" : "#00C6A2" },
                },
                children: <div>{text}</div>,
              };
            },
          });
        }})

      setTableAudience(
        dataChart.filter(({ type }) => type == "Audiencia").map((item) => ({
          id: item.key,
          site: item.value_filter,
          value: item.porcentaje
        }))
      );

      setTableNewAudience(
        dataChart.filter(({ type }) => type == "Nueva audiencia").map((item) => ({
          id: item.key,
          site: item.value_filter,
          value: item.porcentaje
        }))
      );
      
      setTableDataBaseExternal(
        dataChart.filter(({ type }) => type == "BD externa").map((item) => ({
          id: item.key,
          site: item.value_filter,
          value: item.porcentaje
        }))
      );

      setColumn([
        {
          title: "Clasificacion",
          dataIndex: "id",
          key: "id",
          align: "center",
        },
        {
          title: "Sitio",
          dataIndex: "site",
          key: "site",
          align: "center",
        },
        ...columns.filter((x) => x !== undefined)])

      }
  }, [data]);

  const  audiencecolumns= [
    {
      title: "Clasificacion",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Sitio",
      dataIndex: "site",
      key: "site",
      align: "center",
    },
    {
      title: "Audiencia",
      dataIndex: "value",
      key: "suscriptors",
      align: "center",
      render(text, record) {
        return {
          props: {
            className: "TextCharts",
            style: { color: "#391EDE" },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const newaudiencecolumns=[
    {
      title: "Clasificacion",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Sitio",
      dataIndex: "site",
      key: "site",
      align: "center",
    },
    {
      title: "Nueva audiencia",
      dataIndex: "value",
      key: "suscriptors",
      align: "center",
      render(text, record) {
        return {
          props: {
            className: "TextCharts",
            style: { color: "#FF596F" },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  const  externadatabasececolumns= [
    {
      title: "Clasificacion",
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "Sitio",
      dataIndex: "site",
      key: "site",
      align: "center",
    },
    {
      title: "Base Externa",
      dataIndex: "value",
      key: "suscriptors",
      align: "center",
      render(text, record) {
        return {
          props: {
            className: "TextCharts",
            style: { color: "#00C6A2" },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ];

  useEffect(() => {
    const hasOne = dataNewAudience.some((item) => item.key === "ERR01");
    if (hasOne) {
      message.warning('Existe error en la carga de datos de comportamientos.');
    }
  }, [data]);

  return (
    <>
      <br />
      {filters.length === 0 || dataNewAudience.some((item) => item.key === "ERR01") ? (
        <Table
          tableLayout="auto"
          className="TextCharts"
          style={{ marginLeft: 120, marginRight: 70, boxShadow: "none" }}
          dataSource={tableAudience}
          columns={audiencecolumns}
          size="small"
          pagination={{ position: ["bottomLeft"] }}
          loading={showLoadingData}
        />
      ) : filters.length !=0 && dataBaseExternal ?
      (
        <>
          <Row>
            <Col span={12}>
              <Table
                tableLayout="auto"
                className="TextCharts"
                style={{ marginLeft: 120, marginRight: 70, boxShadow: "none" }}
                dataSource={tableAudience}
                columns={audiencecolumns}
                size="small"
                pagination={{ position: ["bottomLeft"] }}
                loading={showLoadingData}
              />
            </Col>
            <Col span={12}>
              <Table
                tableLayout="auto"
                className="TextCharts"
                style={{ marginLeft: 100, marginRight: 70, boxShadow: "none" }}
                dataSource={tableDataBaseExternal}
                columns={externadatabasececolumns}
                size="small"
                pagination={{ position: ["bottomLeft"] }}
                loading={showLoadingData}
              />
            </Col>
          </Row>
        </>
      ):
      (
        <>
          <Row>
            <Col span={12}>
              <Table
                tableLayout="auto"
                className="TextCharts"
                style={{ marginLeft: 120, marginRight: 70, boxShadow: "none" }}
                dataSource={tableAudience}
                columns={audiencecolumns}
                size="small"
                pagination={{ position: ["bottomLeft"] }}
                loading={showLoadingData}
              />
            </Col>
            <Col span={12}>
              <Table
                tableLayout="auto"
                className="TextCharts"
                style={{ marginLeft: 100, marginRight: 70, boxShadow: "none" }}
                dataSource={tableNewAudience}
                columns={newaudiencecolumns}
                size="small"
                pagination={{ position: ["bottomLeft"] }}
                loading={showLoadingData}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TableBehavoirs;
