import { useState } from 'react'
import { Auth } from 'aws-amplify'
import { Modal, Form, Input, Button, Layout, Image, Col, Row, message } from 'antd'
import '../components/UsersManagement/UsersManagement.css'

const { Header, Content, Footer } = Layout;



const LoginPage = () => {

  const [errors, setErrors] = useState()

  const [showChangePassword, setShowChangePassword] = useState(false)
  const [newPassword, setNewPassword] = useState()

  const [showMfa, setShowMfa] = useState(false)
  const [messageMfa, setMessageMfa] = useState('')
  const [codeVerification, setCodeVerification] = useState('')
  const [user, setUser] = useState()

  const signIn = async ({ username, password }) => {
    Auth.signIn(username, password)
      .then(data => {
        const { challengeName, challengeParam } = data
        setUser(data)
        if (challengeName === "NEW_PASSWORD_REQUIRED") setShowChangePassword(true)
        if (challengeName === "SMS_MFA") setShowMfa(true)
        if (challengeName === "SMS_MFA") setMessageMfa(challengeParam.CODE_DELIVERY_DESTINATION)
      }).catch(err => {
        if (err.name !== 'UserNotFoundException' && err.name !== 'NotAuthorizedException') console.log('Error iniciando sesión:', err)
        setErrors('Error iniciando sesión')
      }
      )
  }

  const changePassword = async () => {
    Auth.completeNewPassword(user, newPassword)
      .then(
        data => {
          const { challengeName, challengeParam } = data
          setUser(data)
          if (challengeName === "SMS_MFA") setShowMfa(true)
          if (challengeName === "SMS_MFA") setMessageMfa(challengeParam.CODE_DELIVERY_DESTINATION)
          setShowChangePassword(false)
        }
      )
      .catch(err => {
        setErrors('Error al actualizar contraseña.')
        console.log('Error actualizando contraseña:', err)
        setShowChangePassword(false)
      })
  }

  const sendVerificationMessage = async () => {
    Auth.confirmSignIn(user, codeVerification, 'SMS_MFA')
      .then(data => setUser(data))
      .catch(err => {
        if (err.name === 'CodeMismatchException') message.error('Código inválido.')
      })
  }

  return (
    <Layout
      style={{ marginTop: "-55px", marginLeft: "-49px", marginRight: "-49px", marginBottom: "-49px" }}
      className="layout"
    >
      <Header style={{ backgroundColor: '#0C2375', padding: '35px' }}>
        <Row>
          <Col span={6}>
            <Image
              style={{ marginTop: '-15px' }}
              src="/images/header/Header-Logo.png"
              preview={false}
            />
          </Col>
          <Col
            span={18}
            className="TituloBoldGA"
            style={{ color: 'white', marginLeft: '180px', marginTop: '-15px', fontSize: '25px', userSelect: 'none' }}
          >
            | GESTOR DE AUDIENCIAS - MÉXICO
          </Col>
        </Row>
      </Header>
      <Content style={{ marginBottom: '-80px' }}>

        <main className="UsersManagement">

          <Modal
            title="Cambiar contraseña"
            open={showChangePassword}
            closable={false}
            footer={[]}
          >
            <Form
              name="form-user"
              onFinish={changePassword}
            >
              <Form.Item
                name="password"
                className="TextRegister"
                style={{ marginTop: "-10px" }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Ingrese contraseña",
                  },
                  {
                    min: 10,
                    message: "La contraseña debe tener al menos 10 caracteres",
                  },
                  {
                    pattern: /^(?=.*[a-z])/,
                    message: "La contraseña debe contener al menos una letra minúscula",
                  },
                  {
                    pattern: /^(?=.*[A-Z])/,
                    message: "La contraseña debe contener al menos una letra mayúscula",
                  },
                  {
                    pattern: /^(?=.*[\W_])/,
                    message: "La contraseña debe contener al menos un carácter especial",
                  },
                  {
                    pattern: /^(?=.*[0-9])(?!.*(012|123|234|345|456|567|678|789|890))/,
                    message: "La contraseña debe contener al menos un valor numérico y no debe tener patrones consecutivos",
                  },
                  {
                    pattern: /^(?!.*(Telcel|Mientras|Temporal|Soporte))/i,
                    message: "La contraseña no debe incluir palabras comunes o genéricas",
                  },
                  {
                    pattern: /^(?!.*(.)\1\1)/,
                    message: "La contraseña no debe contener patrones repetitivos de tres caracteres consecutivos",
                  },
                ]}
              >
                <Input.Password
                  style={{ marginTop: "-5px", 'border': 'solid', 'borderWidth': 1, 'borderRadius': 5 }}
                  onChange={({ target }) => setNewPassword(target.value)}
                  placeholder='Nueva contraseña'
                />
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button
                  style={{ marginLeft: "-10px" }}
                  type="primary"
                  htmlType="submit"
                  className="SubtituloRegularGA Button1"
                >
                  Cambiar contraseña
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Código de verificación enviado"
            open={showMfa}
            onOk={() => sendVerificationMessage()}
            onCancel={() => setShowMfa(false)}
          >
            <input
              className="password"
              type='password'
              name='Código de verificación'
              required pattern="(/d).{10,}"
              title="Código de verificación debe contener 4 dígitos"
              style={{ marginLeft: "150px" }}
              onChange={({ target }) => setCodeVerification(target.value)}
              placeholder={`Enviado a ${messageMfa}`}
            />
          </Modal>
          <Form
            name="form-user"
            onFinish={values => signIn(values)}
            onFinishFailed={errorInfo => console.log('Error inicio de sesión:', errorInfo)}
            autoComplete="off"
            className='SignIn'
          >
            <h1 className="h1Login" style={{ userSelect: 'none' }}>
              Iniciar Sesión
            </h1>
            <h3 className="h3Login" style={{ userSelect: 'none' }}>
              Ingresa y comienza a generar tus audiencias
            </h3>
            <p style={{ marginBottom: '10px', userSelect: 'none' }}>
              Usuario
            </p>
            <Form.Item
              name="username"
              className='username'
              style={{ userSelect: 'none' }}
              rules={[
                {
                  required: true,
                  message: 'Ingrese nombre de usuario',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <p style={{ marginBottom: '10px', userSelect: 'none' }}>
              Contraseña
            </p>
            <Form.Item
              name="password"
              style={{ borderTopWidth: '40px', userSelect: 'none' }}
              rules={[
                {
                  required: true,
                  message: (
                    <>
                      Ingrese contraseña <br />
                      (Consulta a un administrador en caso de olvidarla)
                    </>
                  )
                }
              ]}
            >
              <Input.Password style={{ height: '40px' }} />
            </Form.Item>
            <Form.Item >
              <Button type="primary" htmlType="submit">
                Iniciar sesión
              </Button>
            </Form.Item >
          </Form>
          {errors && errors}
        </main>
      </Content>
      <Footer
        style={{
          textAlign: 'center',
          width: '100%',
          marginBottom: '50px',
          scrollMarginLeft: 'no',
          userSelect: 'none',
          marginTop: '10px'
        }}
      >
        Gestor de audiencias ©2024
      </Footer>
    </Layout >

  )
}

export default LoginPage