import { useEffect, useState, useRef } from 'react'
import './ListAudiences.css';

import {
    Row,
    Col,
    Table,
    Button,
    Input,
    Space,
} from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import { API } from 'aws-amplify'
import { ConsoleLogger } from '@aws-amplify/core'
import './ListAudiences.css' 


const ListAudiences = ({ audiences, setAudiences }) => {
    const [data, setData] = useState()
    const [showLoadingData, setShowLoadingData] = useState(true)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    //const [selectedData, setSelectedData] = useStat()
    const pako = require('pako');
    const getData = async (variables) => {
      let nextToken = null;
      let allAudiences = [];
    
      do {
        let query1;
          query1 = `
            query ListAudiences(
              $filter: ModelAudienceFilterInput
              $limit: Int
              $nextToken: String
            ) {
              listAudiences(filter: $filter, limit: $limit, nextToken: $nextToken) {
                items {
                  updatedAt
                  name
                  isPublic
                  id
                  count
                  createdAt
                  user {
                    username
                  }
                  filters {
                    value_filter
                    type_filter
                  }
                }
                nextToken
              }
            }`;
        const response = await API.graphql({
          query: query1,
          variables: { ...variables, nextToken },
        });
    
        const filteredItems = response.data.listAudiences.items;
        const items = filteredItems.filter(item => item !== null);

        for (const item of items) {
          for (const filter of item.filters) {
            if (filter.type_filter === -1) {
              try {
                const base64DecodedData = atob(filter.value_filter);
                // Convierte la cadena decodificada a un array de bytes
                const compressedData = Uint8Array.from([...base64DecodedData].map((c) => c.charCodeAt(0)));
                const decompressedData = pako.inflate(compressedData, { to: 'string' });
                // Now decompressedData contains your original dictionary
                const utf8DecodedString = decodeURIComponent(decompressedData);
                const obj = eval('(' + utf8DecodedString + ')');
                // Inicializa un arreglo para almacenar los resultados
                const transformedData = [];
                // Itera a través de las propiedades del objeto
                for (const key in obj) {
                  if (obj.hasOwnProperty(key)) {
                    const entry = obj[key];
                    const { value_filter, type_filter } = entry;
                    transformedData.push({ value_filter, type_filter });
                  }
                }

                item.filters=transformedData

              } catch (error) {
                console.error("Error al descomprimir el value_filter:", error);
              }
            }
          }
        }
        allAudiences = allAudiences.concat(items);
        nextToken = response.data.listAudiences.nextToken;
      } while (nextToken !== null);

      const mappedAudiences = allAudiences.map((item) => ({
        ...item,
        userName: item.user.username,
        fechaCreacion: item.createdAt ? item.createdAt.substring(0, 10) : '',
      }));

      setData(mappedAudiences.length > 0 ? mappedAudiences : undefined);
      setShowLoadingData(false);
    };
    

    useEffect(() => {
      getData({
        filter: {
          is_deleted: { eq: false },
        },
        limit: 2000,
      });
    }, [])

    useEffect(() => {
      const selectedAudienceIds = audiences.map((row) => row.id);
      setSelectedRowKeys(selectedAudienceIds);
    }, []);

    
    const onSelectTable = (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys); // Establecer las claves de las filas seleccionadas en la tabla
      const selectedAudiences = data.filter((audience) =>
        selectedRowKeys.includes(audience.id)
      ); // Filtrar los elementos seleccionados basados en las claves de las filas seleccionadas
      setAudiences(selectedAudiences); // Establecer los elementos seleccionados en el estado
    };


    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Buscar"
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select(), 100);
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <span style={{ fontWeight: 'bold' }}>{text}</span>
        ) : (
          text
        ),
    });


    return (
        <div>
               <Row
        style={{
          backgroundColor: "white",
          marginLeft: 120,
          marginRight: 0,
          padding: "10px 30px",
        }}
      >
          <Col span="18" style={{ top: "5px" }}>
            <Table
                loading={showLoadingData}
                rowSelection={{
                  selectedRowKeys,
                  onChange: onSelectTable,
                  getCheckboxProps: (record) => ({
                    checked: selectedRowKeys.includes(record.id),
                  }),
                }}
                columns={[
                  {
                    title: "Nombre",
                    dataIndex: "name",
                    filterSearch: true,
                    ...getColumnSearchProps('name'),
                  },
                  {
                    title: "Fecha Creación",
                    dataIndex: "fechaCreacion",
                   
                  },
                  {
                    title: "Creado por",
                    dataIndex: "userName",
                    filterSearch: true,
                    ...getColumnSearchProps('userName'),
                  },
                  {
                    title: "Subscriptores",
                    dataIndex: "count",
                    filterSearch: true,
                  },
                ]}
                tableLayout='fixed'
                dataSource={data}
                pagination={false}
                rowKey='id'
                style={{columnWidth:3000, selections: true}}
                scroll={{ y: 290, x:0 }}
            />
            </Col>
            </Row>
        </div>
    )
}

export default ListAudiences