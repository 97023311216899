import { Column, Bar } from "@ant-design/plots";
import { Card, Divider, Row, Col, Avatar, Typography, Table } from "antd";
import { useState, useEffect } from "react";
import { SubtitleDivider } from "./LineChart/ChartsDivider";
const { Title, Text } = Typography;

const Histogram = ({ data, showLoadingData, filter, typeChart, nameChart, onTop }) => {

  // setear en Audience.jsx setShowLoadingData
  // Pasar argumento showLoadingData
  // Evaluar filtros para pattern de config linea 155
  // Tipo/Orientación (Horizontal o Vertical)
  // xField y yField

  const [config, setConfig] = useState();
  const [table, setTable] = useState();
  const [columns, setColumns] = useState();
  const [maxPorcentaje, setMaxPorcentaje] = useState()
  
  const pattern = ({ value_filter }) => {
    if (filter.map((item) => item.value_filter).includes(value_filter)) {
      return {
        type: "line",
        cfg: {
          size: 3,
          padding: 6,
          rotation: 50,
          lineWidth: 3,
          spacing: 3,
        },
      };
    }
  };

  useEffect(() => {
    if (data.total) {
      let ChartComponent;
      let xField;
      let yField;

      let maxSubscribers = 0;
      let topValue = null;

      data.total.forEach(item => {
        if (
          item.value_filter.toLowerCase() === "no identificado" ||
          item.value_filter.toLowerCase() === "desconocido"
        ) {
          if (data.total.length === 1) {
            topValue = item;
          }
        } else {
          if (item.subscribers > maxSubscribers) {
            maxSubscribers = item.subscribers;
            topValue = item;
          }
        }
      });

      if (topValue) {
        onTop({ name: topValue.value_filter, value: topValue.subscribers.toLocaleString("en-US", { notation: "compact", compactDisplay: "short" }) });
      }

      
      if (typeChart === 'Column') {
        ChartComponent = Column;
        xField= "value_filter";
        yField= "porcentaje";
      } else if (typeChart === 'Bar') {
        ChartComponent = Bar;
        yField= "value_filter";
        xField= "porcentaje";
      }

      const indices = Object.keys(data)
      let dataChart = []

      let columns = indices.map((item, index) => {
        // Se evalua que existan datos en las diferentes audiencias
        if (data[item]) {
          // Se obtiene el total de los subscriptores
          let total = data[item].reduce((r, c) => r + c.subscribers, 0)
          // Se recorren los items para evaluar el tipo de audiencia y sus valores y porcentajes
          data[item].forEach(indicador => {
            indicador.porcentaje = (total === 0) ? 0 : ((indicador.subscribers / total) * 100)
            indicador.type = item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa"
          })

          dataChart.push(...data[item])

          return ({
            title: item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa",
            dataIndex: `value${index}`,
            key: "value",
            align: "center",
            render(text, record) {
              return {
                props: {
                  className: "TextCharts",
                  style: { color: item === 'total' ? "#391EDE" : item === 'newAudience' ? "#FF596F" : "#00C6A2" },
                },
                children: <div>{text}</div>,
              };
            },
          })
        }
      })

      let maxPorcentaje = dataChart.map(({porcentaje})=>porcentaje)
      maxPorcentaje = Math.max(...maxPorcentaje)
      setMaxPorcentaje(maxPorcentaje.toFixed(2)+'%')

      const rows = new Set([...dataChart.map(({value_filter})=>value_filter)])
      const dataTable = []
      Array.from(rows).forEach((item, index) => {
        let values = dataChart.filter(({ value_filter }) => value_filter == item)
        let val = {
          label: item
        }
        values.forEach(({ type, porcentaje }) => {
          switch (type) {
            case 'Audiencia':
              val.value0 = porcentaje.toFixed(2)+'%'
              break
            case 'Nueva audiencia':
              val.value1 = porcentaje.toFixed(2)+'%'
              break
            case 'BD externa':
              val.value2 = porcentaje.toFixed(2)+'%'
              break
            default:
              break
          }
        })
        dataTable.push(val)
      })

      setTable(dataTable.slice(0, 10));

      let config = {
        data: dataChart,
        isGroup: true,
        tooltip: {
          customContent: (title, items) => {
            return (
              <>
                <h4 style={{ marginTop: 16 }}>{title}</h4>
                <ul style={{ paddingLeft: 0 }}>
                  {items?.map((item, index) => {
                    const { name, value, color } = item;
                    return (
                      <>
                        <li
                          key={item.data.key}
                          className="g2-tooltip-list-item"
                          data-index={index}
                          style={{ marginTop: 5, marginBottom: 4, display: 'flex', alignItems: 'center' }}
                        >
                          <span className="g2-tooltip-marker" style={{ backgroundColor: color, padding: 5 }}></span>
                          <span
                            style={{ widht: '100%', display: 'inline-flex', flex: 1, justifyContent: 'space-between' }}
                          >
                            <span style={{ marginLeft: 16, marginRight: 16 }}>{name}:</span>
                            <span className="g2-tooltip-list-item-value">{item.data.subscribers.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                          </span>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </>
            )
          }
        },
        xField: xField,
        yField: yField,
        yAxis: {
          label: {
            formatter: (evt, item) =>
              `${evt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
          },
        },
        //isPercent: true,
        colorfield: "type",
        seriesField: "type",
        color: ({ type }) => {
          if (type === "Audiencia") {
            return "#391EDE";
          }
          if (type === "Nueva audiencia") {
            return "#FF596F";
          }
          if (type === "BD externa") {
            return "#00C6A2";
          }
        },
        barStyle: {
          radius: [2, 2, 0, 0],
        },
        legend: {
          position: "top",
          selected: {
            //"Nueva audiencia": false,
            //"BD externa": false,
          }
        },
        pattern,
        interactions: [
          { type: "element-highlight" },
          { type: "element-active" },
          { type: "brush" }
        ]
      }

      setConfig(<ChartComponent {...config} />)

      setColumns([
        {
          title: "Tipo de indicador",
          dataIndex: "label",
          key: "label",
        },
        ...columns.filter((x) => x !== undefined)])
    }
    
  }, [data])//[data])

  return (
    <>
      <SubtitleDivider subtitle={nameChart} />
      <Row
        align="middle"
        justify="space-between"
        style={{
          backgroundColor: "white",
          marginLeft: 0,
          marginRight: 60,
          padding: 10,
        }}
        gutter={5}
      >
        <Col span={5} push={2} style={{ lineHeight: 0 }}>
          <Title
            className="CifraPrincipalResaltadaGA"
            level={1}
            style={{ fontSize: 30, color: "#391EDE"}}
          >
            {maxPorcentaje}{" "}
            <Avatar size={15} style={{ backgroundColor: "#391EDE" }}></Avatar>
          </Title>
          <Text className="SubmenuRegularGA">Porcentaje de suscriptores</Text>
          <Divider />
        </Col>
      </Row>
      <Row
        style={{
          backgroundColor: "white",
          marginLeft: 0,
          marginRight: 0,
          padding: 10,
        }}
      >
        <Col span={6} push={2}>
          <Table
            tableLayout="auto"
            className="TextCharts"
            dataSource={table}
            columns={columns}
            showHeader={true}
            size="small"
            pagination={false}
            loading={showLoadingData}
          />
        </Col>
        <Col span={14} push={3} align="middle">
          <Card
            style={{
              width: "100%",
              padding: 0,
              borderRadius: 20,
              backgroundColor: "#F8FAFF",
              boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.1)",
            }}
            loading={showLoadingData}
          >
            <div style={{ height: "100%" }}>{config && config}</div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default Histogram;