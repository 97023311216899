import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Input,
    Form,
  } from "antd";
  import { useNavigate } from "react-router-dom";
  import { API } from "aws-amplify";
  import * as queries from "../../graphql/queries";
  import * as mutations from "../../graphql/mutations";

const CreateAudience = ({filters}) => {
    const [view, setView] = useState("");
    const [createAudience, setCreateAudience] = useState(false);
    const [createAudienceView, setCreateAudienceView] = useState(false);
    const [dataAudience, setDataAudience] = useState();
    const [idAudience, setIdAudience] = useState();


    const navigate = useNavigate();

    useEffect(() => {
        if (createAudience) {
          const getData = async () => {
            const allTodos = await API.graphql({
              query: mutations.createAudience,
              variables: {
                //input: todoDetails
                input: {
                  isPublic: false,
                  name: `${createAudience}`,
                  filters: filters,
                  audienceUserId: "614294f9-1827-422e-91d0-5f9235c14c8e",
                },
              },
            });
            setDataAudience(allTodos.data.listAudiences.items);
          };
          getData();
        }
      }, [createAudience]);

    const onFinish = (values: any) => {
        setCreateAudienceView(false);
        setCreateAudience(values.nameAudience);
        setView("");
      };

      const onFinishFailed = (errorInfo: any) => {
        setCreateAudienceView(false);
        setView("");
      };

    return (
        <Form
          className="FormExports"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <h2 className="TituloBoldGA">Crea nueva Audiencia</h2>
          <Form.Item
            className="SubmenuRegularGA"
            name="nameAudience"
            rules={[
              {
                required: true,
                message: "Es requerido el Nombre de la Exportación",
              },
            ]}
          >
            <Input placeholder="Nombre de la audiencia" />
          </Form.Item>

          <Form.Item
            name="descriptionExport"
            rules={[
              { required: false, message: "Please input your password!" },
            ]}
          >
            <Input placeholder="Descripción de la audiencia" />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                navigate(`/audiences/`);
              }}
            >
              Siguiente
            </Button>
          </Form.Item>
        </Form>
      );
}

export default CreateAudience