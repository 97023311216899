/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_cloudfront_headers": {
        "Server": ""
    },
};


export default awsmobile;