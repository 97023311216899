import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Auth } from 'aws-amplify'
import React from 'react'


const Router = () => {
  const navigate = useNavigate()
  const validate = async () => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if (user) return navigate('/audience')
        navigate('/login')
      })
      .catch(err => {
        //console.log(err)
        navigate('/login')
      })
  }
  useEffect(() => {
    validate()
  }, [])
  
  return (
    <>Redirecting...</>
  )
}

export default Router