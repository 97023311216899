import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Row } from 'antd';
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom'
import { UserContext } from '../../../GestorContext'
import { API } from "aws-amplify";
import './HeaderMenu.css'

const HeaderMenu = ({pathname}) => {
    const [menuList, setMenuList] = useState()
    const [current, setCurrent] = useState('');
    const userContext = useContext(UserContext)

    const createLog = async (values) => {
        const usuario = await API.graphql({
         query: `mutation CreateMetrics( $input: CreateMetricsInput!){
                    createMetrics(input:$input) {
                        id
                    }
                }`,
            variables: {
                input : values
              },
            });
      };

    const onClick = (e) => {
        switch (e.key) {
            case "/audience":
                createLog({
                    action:"Ingreso a Principal",
                    id: uuidv4(),
                    user:userContext.username,
                    view:"Principal",
               })
              break;
            case "/audiences":
                createLog({
                    action:"Ingreso a listado de Audiencias",
                    id: uuidv4(),
                    user:userContext.username,
                    view:"Listado de Audiencias",
               })
              break;
            case "/exports":
                createLog({
                    action:"Ingreso a Exportaciones",
                    id: uuidv4(),
                    user:userContext.username,
                    view:"Listado de exportaciones",
               })
              break;
       
      }};

    useEffect(() => {

        const items = [{
            key: '/audience',
            label: (<Link className="SubtituloRegularGA" to='/audience'>Audiencia</Link>),
        },
        {
            key: '/audiences',
            label: (<Link className="SubtituloRegularGA" to='/audiences'>Ver guardadas</Link>),
        }]
        const groups = userContext.signInUserSession.idToken.payload['cognito:groups']
        groups.forEach(item => {
            if (item === 'Admin' || item === 'Company') {
                items.push({
                    key: '/exports',
                    label: (<Link className="SubtituloRegularGA" to='/exports'>Exportaciones</Link>),
                })
            }
        })
        setMenuList(items)

    }, [])

    return (
        <Row
            aling='middle'
            justify='center'
        >
            <Menu
                onClick={onClick}
                mode='horizontal'
                defaultSelectedKeys={pathname}
                items={menuList}
            />
        </Row>
    )
}


export default HeaderMenu
