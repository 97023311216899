import { useEffect, useState, useRef } from 'react'

import { Col, Row, Space, Table, Button, Input, Menu, Dropdown, Modal, Form, message, Transfer } from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify';
import './ListUsersGroups.css'

const { Search } = Input;

const ListUserGroups = props => {
  const [form] = Form.useForm();
  const [data, setData] = useState()
  const [grid, setGrid] = useState()
  const [typeGrid, setTypeGrid] = useState('list')
  const [dataGroups, setDataGroups] = useState()
  const [isModalDeleteGroup, setIsModalDeleteGroup] = useState(false);
  const [isModalCreateGroup, setIsModalCreateGroup] = useState(false);
  const [isModalUpdateGroup, setIsModalUpdateGroup] = useState(false);
  const [visible, setVisible] = useState(false);
  const [targetKeys, setTargetKeys] = useState([]);
  const [listUsersData, setListUsersData] = useState([]);
  const [groupUsernames, setGroupUsernames] = useState([]);
  const [getGroupData, setGetGroupData] = useState([]);
  const [isModalUpdateUsers, setIsModalUpdateUsers] = useState(false);
  
  const [IdGroup, setIdGroup] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px",
              backgroundColor: "#391EDE"
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px"
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        text
      ) : (
        text
      )
  });

  const getData = async () => {
    let nextToken = null;
    let allUsers = [];
    do {
      const allTodos = await API.graphql({
        query: `
          query ListGroups(
            $filter: ModelGroupFilterInput
            $nextToken: String
          ) {
            listGroups(filter: $filter, nextToken: $nextToken) {
              items {
                id
                name
                is_deleted
                updatedAt
                createdAt
              }
              nextToken
            }
          }
        `,
        variables: {
          filter: {is_deleted: {eq: false}},
          nextToken
        },
      });
      const items = allTodos.data.listGroups.items;
      allUsers = allUsers.concat(items);
      nextToken = allTodos.data.listGroups.nextToken;
    } while (nextToken !== null);
    setDataGroups(allUsers);
  };

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    fetchUsers();
  }, []);
  
  useEffect(() => {
    if (getGroupData.getGroup) {
      const groupUserIds = getGroupData.getGroup.users.items.map(item => item.user.id);
      setTargetKeys(groupUserIds);
      const groupUsernames = getGroupData.getGroup.users.items.map(item => item.user.username);
      setGroupUsernames(groupUsernames);
    }
  }, [getGroupData]);
  
const fetchUsers = async () => {
  try {
    const result = await API.graphql(graphqlOperation(listUsers));
    if (result.data.listUsers.items.length > 0) {
      setListUsersData(result.data.listUsers.items);
    } else {
      console.log('No se encontraron usuarios.');
    }
  } catch (error) {
    console.error('Ocurrió un error al obtener los usuarios:', error);
  }
};
  
  const fetchGroup = async (id) => {
    const result = await API.graphql(graphqlOperation(getGroup(id)));
    setGetGroupData(result.data);
  };
  
  const handleOk = async (groupId) => {
    success("Configuración aplicada. Los cambios se reflejarán en unos minutos.")
    setVisible(false);
    try {
      const selectedUsernames = listUsersData.filter(user => targetKeys.includes(user.id)).map(user => user.username);
      await API.graphql(graphqlOperation(updateUsers(selectedUsernames, groupId)));
    } catch {
      console.log("Timeout de espera excedido, el proceso se realizará de forma interna")
    }
  };
  
  const handleChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
  };

  const listUsers = `query MyQuery {
    listUsers(filter: {is_deleted: {eq: false}}) {
      items {
        username
        id
      }
    }
  }`;
  
  const getGroup = (id) => `query MyQuery {
    getGroup(id: "${id}") {
      users {
        items {
          user {
            id
            username
          }
        }
      }
    }
  }`;
  
  const updateUsers = (usernames, groupId) => `mutation MyMutation {
    updateMultipleUserCustom(input: {usernames: ${JSON.stringify(usernames)}, groups: ["${groupId}"]})
  }`;

  useEffect(() => {
    if (data) {
      const onClickMenuAcciones = (key, record) => {
        setIdGroup(record.id);
        if (key === '1') {
          setIsModalUpdateGroup(true)
        }else if (key === '2') {
          setVisible(true);
          fetchGroup(record.id);
        }
        else if (key === '3') {
          setIsModalDeleteGroup(true)
        }
      }

      const menu = (record) => (
        <Menu
          onClick={({ key }) => onClickMenuAcciones(key, record)}
          items={[
            {
              key: '1',
              label: 'Cambiar nombre',
              icon: <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z" fill="black" /> <path id="Clip 4" fillRule="evenodd" clipRule="evenodd" d="M1.33398 2H12.7877V14.6264H1.33398V2Z" fill="white" /> <path id="Fill 3" fillRule="evenodd" clipRule="evenodd" d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z" fill="black" /> <path id="Fill 6" fillRule="evenodd" clipRule="evenodd" d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z" fill="black" /></svg>,
            },
            {
              key: '2',
              label: 'Administrar usuarios',
              icon: <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 30 24"> <path d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"> </path> </svg>,
            },
            {
              key: '3',
              label: 'Eliminar',
              icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z" fill="black" /> <path fillRule="evenodd" clipRule="evenodd" d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z" fill="black" /> <path fillRule="evenodd" clipRule="evenodd" d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z" fill="black" /> </svg>,
            }
          ]}
        />
      )
      
      const columns = [
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Nombre de grupo
                </Col>
                <Col span="4">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'name',
          key: 'id',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name);
          },
          showSorterTooltip: false,
          ...getColumnSearchProps("name")
          //render: text => <a>{text}</a>,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  id
                </Col>
                <Col span="4">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,

          dataIndex: 'id',
          key: 'id',
          sorter: (a, b) => {
            return a.id.localeCompare(b.id);
          },
          showSorterTooltip: false,
          //render: text => <a>{text}</a>,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Fecha de creación
                </Col>
                <Col span="4">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          ...getColumnSearchProps("createdAt"),
          render: (text) => text.substring(0, 10),
          sorter: {
            compare: (a, b) =>
              Date.parse(a.createdAt) - Date.parse(b.createdAt),
          },
          showSorterTooltip: false,
        },
        {
          title: 'Acciones',
          dataIndex: 'permisos',

          render: (_, record) => (
            <Dropdown overlay={() => menu(record)}>
              <a className='optionsButton'>
                <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="1.5" cy="1" r="1" fill="#181D2E" /> <circle cx="1.5" cy="5" r="1" fill="#181D2E" /> <circle cx="1.5" cy="9" r="1" fill="#181D2E" /> </svg>
              </a>
            </Dropdown>
          )
        }
      ]
      setGrid(<Table size="small" columns={columns} dataSource={data} style={{ width: '100%' }} />)
    }
  }, [data, typeGrid])


  useEffect(() => {
    setData(dataGroups)
  }, [dataGroups])

  const handleCancel = () => {
    setIsModalDeleteGroup(false);
    setIsModalCreateGroup(false);
    setIsModalUpdateUsers(false);
  };

  async function onFinishCreate() {
    loading("Creando grupo.")
    try {
      const values = await form.validateFields();
      const name = values.name
      const result = await API.graphql(
        graphqlOperation(`
          mutation MyMutation($name: String!) {
            createGroup(input: { name: $name }) {
              id
            }
          }`,
          { name } // Pasar el valor de "name" como variable
        )
      );
      success("Grupo creado correctamente.")
      setIsModalCreateGroup(false)
    } catch (err) {
      errorMessage("No fue posible crear el grupo, consulta al administrador.")
      console.error("Error al crear el grupo:", err);
    }
  }

  async function onFinishUpdate() {
    loading("Realizando el cambio de nombre del grupo.")
    try {
      const values = await form.validateFields();
      const name = values.name
      const result = await API.graphql({
        query: `
          mutation MyMutation($input: UpdateGroupInput!) {
            updateGroup(input: $input) {
              id
              name
            }
          }`,
          variables: {
            input: {
              id: IdGroup,
              name: name,
            },
          },
        }
      );
      success("El nombre del grupo fue cambiado correctamente.")
      setIsModalUpdateGroup(false)
    } catch (err) {
      errorMessage("No fue posible cambiar el nombre del grupo, consulta al administrador.")
      console.error("Error al crear el grupo:", err);
    }
  }

  async function CreateGroup(){
    await onFinishCreate();
    await getData();
  }

  async function UpdateGroup(){
    await onFinishUpdate();
    await getData();
  }


  const deleteGroup = async () => {
    loading("Eliminando el grupo.")
    try{
      const usuario = await API.graphql({
        query: 
        `
        mutation MyMutation($input: deleteGroupInput = {id: "${IdGroup}"}) {
          deleteGroupCustom(input: $input)
        }`,
      });
      success("El grupo fue eliminado exitosamente.")
      setIsModalDeleteGroup(false)
      getData()
    } catch (error) {
      errorMessage("No fue posible eliminar el grupo, consulta al administrador.")
      warning("No es posible eliminar, verifica que no existan usuarios dentro del grupo")
    }
  };

  const UpdateUsersGroup = async () => {
    handleOk(IdGroup)
  };

  const warning = (ValueWarning) => {
    message.destroy(); 
    message.warning(ValueWarning);
  };

  const success = (valueSuccess) => {
    message.destroy(); 
    message.success(valueSuccess);
  };

  const loading = (ValueLoading) => {
    message.loading({ content: ValueLoading, duration: 0 });
  };

  const errorMessage = (ValueError) => {
    message.destroy(); 
    message.error(ValueError);
  };

  const sortedDataSource = listUsersData
  .map(user => ({
    key: user.id,
    title: user.username,
    disabled: groupUsernames.includes(user.username),
  }))
  .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <div className='ListUsersComponent'>
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalDeleteGroup}
        onOk={deleteGroup}
        onCancel={handleCancel}
      >
        <p>¿Estás seguro que deseas eliminar el grupo de usuario?</p>
      </Modal>

      <Modal open={visible} onOk={UpdateUsersGroup} title="Gestión de usuarios dentro del grupo" onCancel={() => setVisible(false)}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Transfer
            dataSource={sortedDataSource}
            titles={['Lista', 'En grupo']}
            targetKeys={targetKeys}
            onChange={handleChange}
            render={item => item.title}
            style={{ maxWidth: '100%' }} // Ajusta este valor según tus necesidades
          />
        </div>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Registrando Nuevo Grupo"
        open={isModalCreateGroup}
        onCancel={() => setIsModalCreateGroup(false)}
        footer={[
          <Button key="submit" type="primary" onClick={CreateGroup}>
            Guardar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="form-name"
          layout="vertical"
          initialValues={{ remember: false }}
          onFinish={CreateGroup}
        >
          <Form.Item
            className="SubmenuRegularGA"
            name="name"
            rules={[
              {
                required: true,
                message: "Es requerido un nombre para el grupo de usuarios",
              },
              {
                validator: async (_, value) => {
                  // Expresión regular para verificar si el valor contiene espacios en blanco
                  const regex = /^\S*$/;
                  if (!regex.test(value)) {
                    throw new Error('El nombre no puede contener espacios en blanco.');
                  }

                  const validador = await API.graphql({
                    query: `
                      query ListGroups(
                        $filter: ModelGroupFilterInput
                        $nextToken: String
                      ) {
                        listGroups(filter: $filter, nextToken: $nextToken) {
                          items {
                            id
                            name
                            is_deleted
                            updatedAt
                            createdAt
                          }
                        }
                      }
                    `,
                    variables: {
                      filter: { is_deleted: { eq: false }, name: { eq: value } }
                    },
                  });
                  if (validador.data.listGroups.items.length > 0) {
                    throw new Error('No es posible utilizar este nombre en un nuevo grupo, prueba con otro.');
                  }
                },
              }
            ]}
          >
            <Input
              allowClear
              size="large"
              placeholder="Ingresa el nombre del nuevo grupo de usuarios"
              title="Name"
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        clasName="ModalGA"
        title="MODIFICANDO NOMBRE DE GRUPO"
        open={isModalUpdateGroup}
        onCancel={() => setIsModalUpdateGroup(false)}
        footer={[
          <Button key="submit" type="primary" onClick={UpdateGroup}>
            Guardar
          </Button>,
        ]}
      >
        <Form
          form={form}
          name="form-name"
          layout="vertical"
          initialValues={{ remember: false }}
          onFinish={UpdateGroup}
        >
          <Form.Item
            className="SubmenuRegularGA"
            name="name"
            rules={[
              {
                required: true,
                message: "Es requerido un nombre para el grupo de usuarios",
              },
              {
                validator: async (_, value) => {
                  const validador = await API.graphql({
                    query: `
                      query ListGroups(
                        $filter: ModelGroupFilterInput
                        $nextToken: String
                      ) {
                        listGroups(filter: $filter, nextToken: $nextToken) {
                          items {
                            id
                            name
                            is_deleted
                            updatedAt
                            createdAt
                          }
                        }
                      }
                    `,
                    variables: {
                      filter: {is_deleted: {eq: false}, name: {eq: value}}
                    },
                  });
                  //console.log(validador)
                  if (validador.data.listGroups.items.length > 0) {
                    throw new Error('No es posible utilizar este nombre, prueba con otro.');
                  }
                },
              }
            ]}
          >
            <Input
              allowClear
              size="large"
              placeholder="Ingrese el nuevo nombre del grupo"
              title="Name"
              onFinish={UpdateGroup}

            />
          </Form.Item>
        </Form>
      </Modal>

      <Row className='MenuListUsers' align="middle">
        <Col span={7}
          className='TituloBoldGA Col1MenuExports'
          style={{ textTranform: 'uppercase' }}
        >
          Grupos de usuario
        </Col>
        <Col span={2} offset={2}>
          <Button
            style={{ marginLeft: "450px" }}
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => setIsModalCreateGroup(true)}
          >
            Nuevo
          </Button>
        </Col>
        <Col span={2}>
          <Button
            style={{ marginLeft: "450px" }}
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => navigate('/users')}
          >
            Lista de usuarios
          </Button>
        </Col>
      </Row>
      {<>{contextHolder}
      <Row>{grid}</Row></>}
    </div>
  )
}

export default ListUserGroups