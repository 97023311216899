import React from 'react'
import PropTypes from 'prop-types'

import { Card, Avatar, Space, Typography } from 'antd'

import './HighLight.css'

const { Text, Title } = Typography

const formatNumber = value => {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "M" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
        return value >= item.value;
    });
    return item ? (value / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
}

const HighLight = ({ info }) => {
    const { x, y, object } = info
    const y_new = y > 240 ? y - 185 : y
    const x_new = x > 900 ? x - 222 : x

    return (
        <div >
            <Card style={{ borderStyle: 'hidden', width: 222, height: 173, borderRadius: 21, left: x_new, top: y_new }}>
                <Space
                    align="start"
                    size={20}
                    direction='vertical'
                >
                    <Space
                        align="center"
                        size={40}>
                        <Text> POBLACIÓN <br/> {object.properties.description_filter === 'estado_home' ? 'Domicilio' : 'Trabajo'} </Text>
                        <Avatar
                            size={50}
                            style={{ backgroundColor: '#391EDE' }}
                        >
                            <Text
                                strong
                                style={{ color: '#FFFFFF' }}
                            >
                                {formatNumber(object.properties.value)}
                            </Text>
                        </Avatar>
                    </Space>

                    <Space align="start"
                        size={0}
                        direction='vertical'>
                        <Text>Ubicación</Text>
                        <Title level={5}>
                            {object.properties.NOMGEO}
                        </Title>

                    </Space>


                </Space>

            </Card >
        </div>
    )
}

HighLight.propTypes = {}

export default HighLight
