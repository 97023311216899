export const iconMale = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} > <g clipPath="url(#clip0_1128_13267)"> <path d="M0 32.6562L32 32.6562L32 0.65625L0 0.65625L0 32.6562Z" fill="white" fillOpacity="0.01" /> <path d="M4.03223 22.6239L4.03223 28.6239H10.0322" stroke="#0D33B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> <path d="M25.0583 7.32222C21.4135 3.67735 15.5039 3.67735 11.859 7.32222C10.0365 9.14468 9.12533 11.5333 9.12533 13.9219C9.12533 16.3105 10.0365 18.6991 11.859 20.5215C15.5039 24.1665 21.4135 24.1665 25.0583 20.5215C28.7032 16.8767 28.7032 10.9671 25.0583 7.32222Z" stroke="#0D33B9" strokeWidth="2" strokeLinejoin="round" /> <path d="M12 20.6562L5.3656 27.2905" stroke="#0D33B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1128_13267"> <rect width="32" height="32" fill="white" transform="translate(32 32.6562) rotate(-180)" /> </clipPath> </defs> </svg>

export const iconFemale = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} > <g clipPath="url(#clip0_1128_13266)"> <path fillRule="evenodd" clipRule="evenodd" d="M16 2.65625C13.8783 2.65625 11.8434 3.49911 10.3431 4.9994C8.84285 6.49969 8 8.53452 8 10.6563C8 12.778 8.84285 14.8128 10.3431 16.3131C11.8434 17.8134 13.8783 18.6563 16 18.6563C18.1217 18.6563 20.1566 17.8134 21.6569 16.3131C23.1571 14.8128 24 12.778 24 10.6563C24 8.53452 23.1571 6.49969 21.6569 4.9994C20.1566 3.49911 18.1217 2.65625 16 2.65625ZM6 10.6563C6.00002 8.72108 6.56154 6.82748 7.61645 5.20512C8.67137 3.58276 10.1743 2.30134 11.9431 1.51626C13.7119 0.731185 15.6705 0.476187 17.5813 0.782192C19.4921 1.0882 21.2731 1.94206 22.7083 3.24022C24.1434 4.53838 25.1711 6.22507 25.6666 8.09573C26.1621 9.96638 26.1042 11.9406 25.4999 13.779C24.8957 15.6174 23.7709 17.241 22.2622 18.4529C20.7534 19.6647 18.9255 20.4127 17 20.6063V24.6563H21C21.2652 24.6563 21.5196 24.7616 21.7071 24.9491C21.8946 25.1367 22 25.391 22 25.6563C22 25.9215 21.8946 26.1758 21.7071 26.3634C21.5196 26.5509 21.2652 26.6563 21 26.6563H17V31.6563C17 31.9215 16.8946 32.1758 16.7071 32.3634C16.5196 32.5509 16.2652 32.6563 16 32.6563C15.7348 32.6563 15.4804 32.5509 15.2929 32.3634C15.1054 32.1758 15 31.9215 15 31.6563V26.6563H11C10.7348 26.6563 10.4804 26.5509 10.2929 26.3634C10.1054 26.1758 10 25.9215 10 25.6563C10 25.391 10.1054 25.1367 10.2929 24.9491C10.4804 24.7616 10.7348 24.6563 11 24.6563H15V20.6063C12.5334 20.3583 10.2467 19.2031 8.58376 17.3645C6.92077 15.526 5.99997 13.1353 6 10.6563Z" fill="#0D33B9" /> </g> <defs> <clipPath id="clip0_1128_13266"> <rect width="32" height="35" fill="white" transform="translate(0 0.65625)" /> </clipPath> </defs> </svg>

export const iconUnknow = <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: 270 }} ></svg>

export const iconArrowUp = <svg className="rotate" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.340134 0.340134C0.752418 -0.0721496 1.39757 -0.10963 1.8522 0.227693L1.98245 0.340134L12 10.3572L22.0176 0.340134C22.4298 -0.0721496 23.075 -0.10963 23.5296 0.227693L23.6599 0.340134C24.0721 0.752418 24.1096 1.39757 23.7723 1.8522L23.6599 1.98245L12.8212 12.8212C12.4089 13.2334 11.7637 13.2709 11.3091 12.9336L11.1788 12.8212L0.340134 1.98245C-0.113378 1.52893 -0.113378 0.793646 0.340134 0.340134Z" fill="#FAFBFF" />
</svg>

export const iconArrowDown = <svg className="rotate down" width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.340134 0.340134C0.752418 -0.0721496 1.39757 -0.10963 1.8522 0.227693L1.98245 0.340134L12 10.3572L22.0176 0.340134C22.4298 -0.0721496 23.075 -0.10963 23.5296 0.227693L23.6599 0.340134C24.0721 0.752418 24.1096 1.39757 23.7723 1.8522L23.6599 1.98245L12.8212 12.8212C12.4089 13.2334 11.7637 13.2709 11.3091 12.9336L11.1788 12.8212L0.340134 1.98245C-0.113378 1.52893 -0.113378 0.793646 0.340134 0.340134Z" fill="#FAFBFF" />
</svg>

export const iconUsers = <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M16.6667 10.2857C19.4281 10.2857 21.6667 7.98315 21.6667 5.14284C21.6667 2.30253 19.4281 0 16.6667 0C13.9053 0 11.6667 2.30253 11.6667 5.14284C11.6667 7.98315 13.9053 10.2857 16.6667 10.2857ZM16.666 23.9998C22.1888 23.9998 26.666 21.6973 26.666 18.857C26.666 16.0167 22.1888 13.7142 16.666 13.7142C11.1431 13.7142 6.66597 16.0167 6.66597 18.857C6.66597 21.6973 11.1431 23.9998 16.666 23.9998ZM7.30276 13.7467C3.18665 14.0078 0 15.8125 0 18C0 19.8033 2.16581 21.3465 5.23272 21.9792C4.54739 21.0247 4.16667 19.9683 4.16667 18.8571C4.16667 16.897 5.35127 15.1076 7.30276 13.7467ZM29.1662 18.8571C29.1662 19.9683 28.7855 21.0247 28.1002 21.9792C31.1671 21.3465 33.3329 19.8033 33.3329 18C33.3329 15.8125 30.1462 14.0078 26.0301 13.7467C27.9816 15.1076 29.1662 16.897 29.1662 18.8571ZM22.8635 9.48925C23.6856 8.25156 24.1663 6.75497 24.1663 5.14288C24.1663 4.60793 24.1134 4.08569 24.0126 3.58141C24.3245 3.48209 24.656 3.4286 24.9996 3.4286C26.8406 3.4286 28.333 4.96362 28.333 6.85716C28.333 8.7507 26.8406 10.2857 24.9996 10.2857C24.1868 10.2857 23.4419 9.98647 22.8635 9.48925ZM8.33306 3.4286C8.67671 3.4286 9.00821 3.48209 9.32012 3.58141C9.21934 4.08569 9.1664 4.60793 9.1664 5.14288C9.1664 6.75497 9.64715 8.25156 10.4692 9.48925C9.89077 9.98647 9.14589 10.2857 8.33306 10.2857C6.49211 10.2857 4.99973 8.7507 4.99973 6.85716C4.99973 4.96362 6.49211 3.4286 8.33306 3.4286Z" fill="#7084B1" />
</svg>

export const iconFilters = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0.923077 4.30769H8.74279C9.14459 5.72831 10.4507 6.76923 12 6.76923C13.5493 6.76923 14.8554 5.72831 15.2572 4.30769H23.0769C23.5867 4.30769 24 3.89442 24 3.38462C24 2.87481 23.5867 2.46154 23.0769 2.46154H15.2572C14.8554 1.04092 13.5493 0 12 0C10.4507 0 9.14459 1.04092 8.74279 2.46154H0.923077C0.413276 2.46154 0 2.87481 0 3.38462C0 3.89442 0.413276 4.30769 0.923077 4.30769ZM18.1538 8.6153C16.2846 8.6153 14.7692 10.1306 14.7692 11.9999C14.7692 13.8692 16.2846 15.3845 18.1538 15.3845C19.7031 15.3845 21.0093 14.3436 21.4111 12.923H23.0769C23.5867 12.923 24 12.5097 24 11.9999C24 11.4901 23.5867 11.0768 23.0769 11.0768H21.4111C21.0093 9.65622 19.7031 8.6153 18.1538 8.6153ZM0 11.9999C0 11.4901 0.413276 11.0768 0.923077 11.0768H12C12.5098 11.0768 12.9231 11.4901 12.9231 11.9999C12.9231 12.5097 12.5098 12.923 12 12.923H0.923077C0.413276 12.923 0 12.5097 0 11.9999ZM2.58894 21.5385H0.923077C0.413276 21.5385 0 21.1252 0 20.6154C0 20.1056 0.413276 19.6923 0.923077 19.6923H2.58894C2.99075 18.2717 4.29689 17.2308 5.84615 17.2308C7.71543 17.2308 9.23077 18.7461 9.23077 20.6154C9.23077 22.4847 7.71543 24 5.84615 24C4.29689 24 2.99075 22.9591 2.58894 21.5385ZM11.0769 20.6154C11.0769 20.1056 11.4902 19.6923 12 19.6923H23.0769C23.5867 19.6923 24 20.1056 24 20.6154C24 21.1252 23.5867 21.5385 23.0769 21.5385H12C11.4902 21.5385 11.0769 21.1252 11.0769 20.6154Z" fill="#7084B1" />
</svg>