/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://n5njh2w5nrbrzc7ihdlw6d6ig4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rn3ykubkdzas3nc4c4blfcvte4",
    "aws_cognito_identity_pool_id": "us-east-1:ce5ec3d1-db5f-48aa-a8c9-6a9cc1d422b1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mch0qp5IV",
    "aws_user_pools_web_client_id": "7smq3feo64v0gt5n9cvq74r2kh",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "10",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_content_delivery_bucket": "gestoraudienciasapp-20230329141314-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dcsx2qsgo4b2x.cloudfront.net",
    "aws_user_files_s3_bucket": "bucketgestoraudienciasapp113802-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
