import { message, Dropdown, Menu, Space, Table, Tag, Row, Col, Input, Button, Modal, Card, Switch, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect, useContext, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import "./Exports.css";
import { v4 as uuidv4 } from 'uuid';
import { SearchOutlined } from "@ant-design/icons";
import { UserContext } from '../../GestorContext'
const { Text, Title } = Typography;

const Exports = () => {
  const [data, setData] = useState();
  const [permission] = useState();
  const [idAudience, setIdAudience] = useState();
  const [audienceName, setAudienceName] = useState();
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpenUpdate, setIsModalOpenUpdate] = useState(false);
  const [showLoadingData, setShowLoadingData] = useState(false);
  const [dataExports, setDataExports] = useState();
  const [searchText,setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const searchInput = useRef(null);
  const [status, setStatus] = useState();
  const pako = require('pako');
  var date = new Date();
  var year = date.getFullYear();
  var month = (1 + date.getMonth()).toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var formattedDate = year + '/' + month + '/' + day;

  //const [columns, setColumns] = useState();

  const navigate = useNavigate();

  const userContext = useContext(UserContext)
  const expandedRowRender = (index) => {
    const menu = (record) => {
      return (
        <Menu
          onClick={onClickMenuAcciones(record)}
          items={[
            {
              key: "1",
              label: "Ver / Editar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z"
                    fill="black"
                  />
                  <path
                    id="Clip 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 2H12.7877V14.6264H1.33398V2Z"
                    fill="white"
                  />
                  <path
                    id="Fill 3"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "2",
              label: "Exportar",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="1 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.666 5.86279H4.33295V15.0255H17.666V5.86279Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.28935 15.0255H14.7093C16.3393 15.0255 17.666 13.6995 17.666 12.0688V8.81879C17.666 7.18879 16.3393 5.86279 14.7093 5.86279H14.0873C13.8113 5.86279 13.5873 6.08679 13.5873 6.36279C13.5873 6.63879 13.8113 6.86279 14.0873 6.86279H14.7093C15.7887 6.86279 16.666 7.74013 16.666 8.81879V12.0688C16.666 13.1481 15.7887 14.0255 14.7093 14.0255H7.28935C6.21068 14.0255 5.33268 13.1481 5.33268 12.0688V8.81279C5.33268 7.73746 6.20735 6.86279 7.28202 6.86279H7.91068C8.18668 6.86279 8.41068 6.63879 8.41068 6.36279C8.41068 6.08679 8.18668 5.86279 7.91068 5.86279H7.28202C5.65602 5.86279 4.33268 7.18613 4.33268 8.81279V12.0688C4.33268 13.6995 5.65935 15.0255 7.28935 15.0255"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.5 1.3335H10.5V10.3608H11.5V1.3335Z"
                    fill="white"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11 10.3608C11.276 10.3608 11.5 10.1368 11.5 9.86083V1.8335C11.5 1.5575 11.276 1.3335 11 1.3335C10.724 1.3335 10.5 1.5575 10.5 1.8335V9.86083C10.5 10.1368 10.724 10.3608 11 10.3608"
                    fill="black"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.9435 4.28601C13.0709 4.28601 13.1989 4.23734 13.2962 4.14001C13.4915 3.94534 13.4929 3.62934 13.2975 3.43334L11.3542 1.48134C11.1669 1.29268 10.8335 1.29268 10.6462 1.48134L8.70219 3.43334C8.50752 3.62934 8.50819 3.94534 8.70352 4.14001C8.89952 4.33468 9.21552 4.33468 9.41019 4.13868L11.0002 2.54268L12.5895 4.13868C12.6869 4.23734 12.8155 4.28601 12.9435 4.28601"
                    fill="black"
                  />
                </svg>
              ),
            },
            {
              key: "3",
              label: "Permiso",
              icon: (
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    id="Clip 2"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.33398 9.30469H11.4565V14.0378H1.33398V9.30469Z"
                    fill="white"
                  />
                  <path
                    id="Fill 1"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.39532 10.3047C4.54265 10.3047 2.33398 10.5427 2.33398 11.6794C2.33398 12.5807 3.70065 13.038 6.39532 13.038C9.08998 13.038 10.4567 12.576 10.4567 11.666C10.4567 10.7627 9.08998 10.3047 6.39532 10.3047ZM6.39532 14.038C5.01132 14.038 1.33398 14.038 1.33398 11.6794C1.33398 9.30469 5.14332 9.30469 6.39532 9.30469C8.56798 9.30469 11.4567 9.54935 11.4567 11.666C11.4567 14.038 7.64732 14.038 6.39532 14.038Z"
                    fill="black"
                  />
                  <path
                    id="Fill 4"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.3947 2.33325C5.05804 2.33325 3.9707 3.42059 3.9707 4.75659C3.9707 6.09259 5.05804 7.17992 6.3947 7.17992H6.41537C7.05804 7.17725 7.66337 6.92458 8.11737 6.46658C8.57204 6.00992 8.8207 5.40259 8.81804 4.75858C8.81804 3.42059 7.7307 2.33325 6.3947 2.33325ZM6.3947 8.17992C4.5067 8.17992 2.9707 6.64392 2.9707 4.75659C2.9707 2.86925 4.5067 1.33325 6.3947 1.33325C8.28204 1.33325 9.81804 2.86925 9.81804 4.75659C9.82204 5.66592 9.46937 6.52458 8.8267 7.17125C8.18537 7.81858 7.3287 8.17659 6.41737 8.17992H6.3947Z"
                    fill="black"
                  />
                  <path
                    id="Fill 6"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9897 7.42118C10.7444 7.42118 10.5304 7.24051 10.4951 6.99051C10.4571 6.71718 10.6471 6.46384 10.9204 6.42584C11.7524 6.30918 12.3804 5.58718 12.3817 4.74584C12.3817 3.90984 11.7831 3.20451 10.9597 3.06984C10.6871 3.02451 10.5024 2.76784 10.5471 2.49518C10.5917 2.22251 10.8497 2.03918 11.1211 2.08251C12.4311 2.29718 13.3817 3.41784 13.3817 4.74651C13.3791 6.08318 12.3811 7.23118 11.0597 7.41651C11.0364 7.41984 11.0131 7.42118 10.9897 7.42118Z"
                    fill="black"
                  />
                  <path
                    id="Clip 9"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.8984 8.98877H14.6543V12.3071H11.8984V8.98877Z"
                    fill="white"
                  />
                  <path
                    id="Fill 8"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.2653 12.3071C13.0633 12.3071 12.8733 12.1838 12.798 11.9845C12.7 11.7265 12.83 11.4371 13.088 11.3398C13.6546 11.1251 13.6546 10.8631 13.6546 10.7511C13.6546 10.3731 13.2073 10.1145 12.3253 9.98312C12.052 9.94179 11.8633 9.68712 11.904 9.41446C11.9453 9.14112 12.2046 8.95779 12.4726 8.99312C14.2766 9.26312 14.6546 10.0985 14.6546 10.7511C14.6546 11.2371 14.4446 11.8951 13.4426 12.2745C13.3846 12.2965 13.3246 12.3071 13.2653 12.3071Z"
                    fill="black"
                  />
                </svg>
              ),
            },
          ]}
        />
      );
    };
    const onClickMenuAcciones = (record) => (e) => {
      switch (e.key) {
        case "1":
          navigate(`/audience/${record.id}`);
          break;
        case "2":
          navigate(`/export/${record.id}`);
          break;
        case "3":
          setIsModalOpenUpdate(true);
          setIdAudience(record.id);
          break;
        default:
      }
    };

    const auxData = []
    index.audience.items.map(e => auxData.push(e.audience))
    return (
      <Row>
        {
          auxData.map((item, index) => {
            return (
              <Col className="ColCard" span={6}>
                <Card
                  className="cardResumen"
                  title={item.name}
                  loading={showLoadingData}
                  extra={

                    <svg
                      width="3"
                      height="10"
                      viewBox="0 0 3 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="1.5" cy="1" r="1" fill="#181D2E" />
                      <circle cx="1.5" cy="5" r="1" fill="#181D2E" />
                      <circle cx="1.5" cy="9" r="1" fill="#181D2E" />
                    </svg>

                  }
                >
                  <div style={{ margin: -5, height: 190 }}>
                    <Row gutter={2} style={{ marginBottom: 5 }}>
                      <Col span={17}>
                        <Space direction="vertical" size={0}>
                          <Text type="secondary">Creador por</Text>
                          <Text>{item.user.username}</Text>
                        </Space>
                      </Col>
                      <Col span={7} align="end">
                        <Text type="secondary">Compartir</Text>
                        <Switch checked={item.isPublic} disabled={true} />
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 5 }}>
                      <Col className="ColComportamientosCard">
                        <Text type="secondary">Comportamientos</Text>
                        <br />
                        <div className="ComportamientosCard" direction="vertical" size={0}>
                          {auxData
                            ?
                            auxData[index].filters.map(e => {
                              switch (e.type_filter) {
                                case 1:
                                  return (
                                    <><span className="SubmenuBoldGA">Genero:</span> {e.value_filter}; </>
                                  )
                                case 2:
                                  return (
                                    <><span className="SubmenuBoldGA">Edad:</span> {e.value_filter}; </>
                                  )
                                case 3:
                                  return (
                                    <><span className="SubmenuBoldGA">Ingreso:</span> {e.value_filter}; </>
                                  )
                                case 4:
                                  return (
                                    <><span className="SubmenuBoldGA">Ubicacion:</span> {e.value_filter}; </>
                                  )
                                case 5:
                                  return (
                                    <><span className="SubmenuBoldGA">Comportamiento:</span> {e.value_filter}; </>
                                  )
                                case 6:
                                  return (
                                    <><span className="SubmenuBoldGA">Marca:</span> {e.value_filter}; </>
                                  )
                                case 7:
                                  return (
                                    <><span className="SubmenuBoldGA">OS:</span> {e.value_filter}; </>
                                  )
                                case 8:
                                  return (
                                    <><span className="SubmenuBoldGA">Tipo:</span> {e.value_filter}; </>
                                  )
                                case 9:
                                  return (
                                    <><span className="SubmenuBoldGA">Fecha:</span> {e.value_filter}; </>
                                  )
                                case 10:
                                  return (
                                    <><span className="SubmenuBoldGA">Hora:</span> {e.value_filter}; </>
                                  )
                                default: break;
                              }
                            }
                            )
                            :
                            null
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row justify="middle">
                        <Col span={12}>
                        <Space direction="vertical" size={5}>
                          <Text type="secondary">Población</Text>
                          <Title level={4} style={{ color: "#391EDE"}}>
                              {item.count ? item.count.toLocaleString("en-US", {
                                notation: "compact",
                                compactDisplay: "short",
                              }) : "<60"}
                          </Title>
                        </Space>
                      </Col>
                    </Row>
                  </div>
                </Card>

              </Col>
            );
          })
        }
      </Row>
    );
  };

  const warning = (message) => {
    messageApi.open({
      type: 'warning',
      content: message,
    });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const updateExport = async (values) => {
    await API.graphql(graphqlOperation(`
        mutation UpdateExport(
        $input: UpdateExportInput!
        $condition: ModelExportConditionInput
        ) {
        updateExport(input: $input, condition: $condition) {
            id
        }
    }` ,
      {
        input: values
      }));
    if (userContext) {
      setShowLoadingData(true)
      getData(userContext);
    }
  };

  const onClickMenuExports = (record) => (e) => {
    switch (e.key) {
      case "1":
        if (record.status === "ACTIVO" && formattedDate > record.dateEnd) {
          warning("La exportación no se puede editar, puede duplicar la exportación.");
          break;
        } if (record.status === "EN_CURSO") {
          warning("La exportación actualmente se encuentra en curso, espera para editarla.");
          break;
        } if (record.status === "PROCESADO") {
          warning("La exportación actualmente se encuentra en transferencia, espera para editarla.");
          break;
        } if (record.status === "TRANSFIRIENDO") {
          warning("La exportación actualmente se encuentra en transferencia, espera para editarla.");
          break;
        } else {
          navigate(`/export/${record.id}?mode=upd`);
          break;
        }
      case "2":
        navigate(`/export/${record.id}?mode=dup`);
        break;
      {/* CASE PARA INICIAR EXPORTACIÓN, COMENTADO POR SOLICITUD DEL CLIENTE
        case "3":
        if (record.status === "EN_CURSO" && formattedDate < record.dateEnd) {
          warning("La exportación actualmente se encuentra iniciada.");
          break;
        } if (formattedDate > record.dateEnd) {
          warning("La exportación no se puede inicilizar, puede duplicar la exportación.");
          break;
        } if (record.status === "NO_INICIADO") {
          startExport({ id_export: record.id });
          updateExport({
            id: record.id,
            dateStart: formattedDate,
            status: "EN_CURSO"
          });
          break;
        }
        else {
          startExport({ id_export: record.id });
          updateExport({
            id: record.id,
            status: "EN_CURSO"
          });
          break;
        }
      */}
      case "4":
        if (record.status === "ACTIVO" && formattedDate <= record.dateEnd) {
          updateExport({
            id: record.id,
            status: 'DETENIDO'
          });
          break;
        } else {
          warning("La exportación no puede detenerse.")
          break;
        }
      case "5":
        if (record.status === "EN_CURSO") {
          warning("La exportación no puede eliminarse.");
          break;
        } else {
          setAudienceName(record.name);
          setStatus(record.status);
          setIdAudience(record.id);
          setIsModalOpenDelete(true);
          break;
        }
      default:
    }
  };

  const getData = async (userContext) => {
    let nextToken = null;
    let allExports = [];
  
    do {
      const allTodos = await API.graphql({
        query: `
          query ListExports(
            $filter: ModelExportFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listExports(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                name
                status
                dateEnd
                dateStart
                createdAt
                count
                description
                error
                user {
                  name
                  username
                }
                audience {
                  items {
                    audience {
                      name
                      isPublic
                      id
                      count
                      error
                      user {
                        name
                        username
                      }
                      filters {
                        type_filter
                        value_filter
                      }
                    }
                  }
                }
              }
              nextToken
            }
          }
        `,
        variables: {
          filter: {
            //exportUserId: { eq: userContext.attributes.sub },
            is_deleted: { eq: false },
          },
          nextToken,
        },
      });
  
const exportedItems = allTodos.data.listExports.items;

// Filtrar y eliminar los objetos nulos
const items = exportedItems.filter(item => item !== null);

for (const item of items) {
  for (const audienceItem of item.audience.items) {
    const audience = audienceItem.audience;
    for (const filter of audience.filters) {
      if (filter.type_filter === -1) {
        try {
          const base64DecodedData = atob(filter.value_filter);
          // Convierte la cadena decodificada a un array de bytes
          const compressedData = Uint8Array.from([...base64DecodedData].map((c) => c.charCodeAt(0)));
          const decompressedData = pako.inflate(compressedData, { to: 'string' });
          // Now decompressedData contains your original dictionary
          const utf8DecodedString = decodeURIComponent(decompressedData);

          const obj = eval('(' + utf8DecodedString + ')');

          // Inicializa un arreglo para almacenar los resultados
          const transformedData = [];

          // Itera a través de las propiedades del objeto
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const entry = obj[key];
              const { value_filter, type_filter } = entry;
              transformedData.push({ value_filter, type_filter });
            }
          }

          // Asigna transformedData como el único elemento de audience.filters
          audience.filters = transformedData
        } catch (error) {
          console.error("Error al descomprimir el value_filter:", error);
        }
      }
    }
  }
}

console.log(items);
allExports = allExports.concat(items);
nextToken = allTodos.data.listExports.nextToken;
    } while (nextToken !== null);
  
    const auxDataExports = allExports.map((item) => ({
      ...item,
      key: item.id,
    }));
  
    setDataExports(auxDataExports);
    setShowLoadingData(false);
  };
  

  useEffect(() => {
    if (userContext && !isModalOpenUpdate) {
      setShowLoadingData(true)
      getData(userContext);
    }
  }, [userContext, isModalOpenUpdate, isModalOpenDelete]);

  const handleCancel = () => {
    setIsModalOpenUpdate(false);
    setIsModalOpenDelete(false);
  };

  async function handleChange() {
    setShowLoadingData(true)
    try {
      await API.graphql({
        query: `
          mutation UpdateAudience(
            $input: UpdateAudienceInput!
          ) {
            updateAudience(input: $input) {
              id
            }
        }`,
        variables: {
          input: {
            id: idAudience,
            isPublic: !permission,
          },
        }
      }
      );
      setIsModalOpenUpdate(false);
    } catch (err) {
      //console.log(err);
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px",
              backgroundColor: "#391EDE"
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px"
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        text
      ) : (
        text
      )
  });

  async function deleteFunction() {
    const result = await API.graphql({
      query: `
          mutation updateExport(
          $input: updateExportInput!
          ) {
          updateExportLambda(input: $input) {
              id
          }
      }` ,
      variables: {
        input: {
          id: idAudience,
          audiences:[],
          is_deleted: true
        },
      }
    }
    );
    setIsModalOpenDelete(false);
  }

  const createLog = async (values) => {
    const usuario = await API.graphql({
     query: `mutation CreateMetrics( $input: CreateMetricsInput!){
                createMetrics(input:$input) {
                    id
                }
            }`,
        variables: {
            input : values
          },
        });
  };

  async function DeleteExport(){
    await deleteFunction();
    await createLog({
      action:"Eliminación de exportación",
      id: uuidv4(),
      user:userContext.username,
      view:"Listado de exportaciones",
    });
  }


  //Funcionalidad de iniciar exportación
  {/*async function startExport(input) {
    //console.log(input, "valor al iniciar export")
    const allTodos = await API.graphql({
      query: `
        query StartExport ($input : startExportInput) {
          startExport(input: $input)
        }`,
      variables: {
        input: input,
      },
    });
    if (userContext) {
      setShowLoadingData(true)
      getData(userContext);
    }
  }*/}

  useEffect(() => {
    setData(dataExports);
  }, [dataExports])

  return (

    <div className="ExportsComponent">
      <Row className="MenuExports" align="middle">
        <Col span={5} className="TituloBoldGA Col1MenuExports">
          Exportaciones
        </Col>
        <Col span={6} offset={6}>
          <Button
            style={{ marginLeft: "350px" }}
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => navigate("/export")}
          >
            Crear nueva exportación
          </Button>
        </Col>
      </Row>
      {dataExports ? (<>{contextHolder}
        <Modal title="ETIQUETAS DE ESTATUS" open={open} onCancel={onClose}
          footer={[
            <Button key="submit" type="primary" style={{ padding: "1px" }} onClick={onClose}>ACEPTAR</Button>,
            <Button key="submit" style={{ display: 'none' }} disabled={true}>
              Submit
            </Button>,
          ]}>
          <>
          <Row align="center">
              <Col span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#464AD2">
                  PROCESADO
                </Tag>
              </Col>
              <Col span={18} push={0} align="left">
      -> Los datos se han cargado, transferencia pendiente.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#9B4DF2">
                  TRANSFIRIENDO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px", marginLeft: "5px" }} span={18} push={0} align="left">
      -> Los datos se encuentran en proceso de transferencia.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#109DFA">
                  ACTIVO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación se encuentra en intervalo de ejecución.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#5f9ea0">
                  NO INICIADO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación aún no inicia su intervalo de ejecución.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#024A86">
                  EN CURSO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación se encuentra ejecutándose.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#87d068">
                  FINALIZADO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación terminó su lapso de ejecución.
              </Col>
            </Row>
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#ffA500">
                  DETENIDO
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación se encuentra detenida.
              </Col>
            </Row >
            <br />
            <Row align="center">
              <Col style={{ marginTop: "-10px" }} span={5} push={0}>
                <Tag style={{ padding: "3px", fontSize: "13px" }} className="tagExports" color="#f50">
                  ERROR
                </Tag>
              </Col>
              <Col style={{ marginTop: "-10px" }} span={18} push={0} align="left">
      -> La exportación se encuentra en error.
              </Col>
            </Row>
            <br />
          </>
        </Modal>
        <Table
          className="ExportsTable SubmenuRegularGA"
          columns={[
            {
              title: (
                <>
                  <Row>
                    <Col className="ColHeader2" span="18" style={{ top: "5px" }}>
                      Nombre de la exportación
                    </Col>
                    <Col span="4" style={{ top: "8px" }}>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "name",
              key: "name",
              sorter: (a, b) => {
                return a.name.localeCompare(b.name);
              },
              showSorterTooltip: false,
              ...getColumnSearchProps("name")
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Fecha de modificación
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "updatedAt",
              key: "dateModification",
              render: (text) => {
                return moment(text).format("YYYY/MM/DD");
              },
              sorter: {
                compare: (a, b) =>
                  Date.parse(a.createdAt) - Date.parse(b.createdAt),
              },
              showSorterTooltip: false,
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Creado por
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "user",

              render: (text) => <>{text.username}</>,
              sorter: (a, b) => {
                if (a.user.username < b.user.username) return -1;
                if (a.user.username > b.user.username) return 1;
                return 0;
              },
              showSorterTooltip: false,
              //cambio en el render para visualizar nombre de usuario
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Fecha de inicio
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              key: "dateStart",
              dataIndex: "dateStart",
              sorter: {
                compare: (a, b) => Date.parse(a.dateStart) - Date.parse(b.dateStart),
              },
              showSorterTooltip: false,
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Fecha de finalización
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              defaultSortOrder: 'descend',
              dataIndex: "dateEnd",
              key: "dateEnd",
              sorter: {
                compare: (a, b) => Date.parse(a.dateEnd) - Date.parse(b.dateEnd),
              },
              showSorterTooltip: false,
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Estatus
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "status",
              key: "id",
              render: (text, index) => {
                switch (text) {
                  case "ACTIVO": //Solo es finalizado cuando el today>día de finalización
                    if (formattedDate > index.dateEnd) {
                      return <Tag className="tagExports" color="#87d068" onClick={showDrawer}>
                        FINALIZADO
                      </Tag>
                    } else {
                      return <Tag className="tagExports" color="#109DFA" onClick={showDrawer}>
                        ACTIVO
                      </Tag>
                    }
                  case "NO_INICIADO": //Solo es finalizado cuando el today>día de finalización
                    return <Tag className="tagExports" color="#5f9ea0" onClick={showDrawer}>
                      NO INICIADO
                    </Tag>
                  case "ERROR":
                    return <Tag className="tagExports" color="#f50" onClick={showDrawer}>
                      ERROR
                    </Tag>
                  case "DETENIDO": //No debería existir, no inicializado. SE DEBE EVALUAR DETENIDO E INICIALIZADO
                    if (formattedDate > index.dateEnd) {
                      return <Tag className="tagExports" color="#87d068" onClick={showDrawer}>
                        FINALIZADO
                      </Tag>
                    } else {
                      return <Tag className="tagExports" color="#ffA500" onClick={showDrawer}>
                        DETENIDO
                      </Tag>
                    }
                  case "PROCESADO":
                    return <Tag className="tagExports" color="#464AD2" onClick={showDrawer}>
                      PROCESADO
                    </Tag>
                  case "TRANSFIRIENDO":
                    return <Tag className="tagExports" color="#9B4DF2" onClick={showDrawer}>
                      TRANSFIRIENDO
                    </Tag>
                  default: 
                    return <Tag className="tagExports" color="#024A86" onClick={showDrawer}>
                      EN CURSO
                    </Tag>
                }
              },

              showSorterTooltip: false,
            },
            {
              title: (
                <>
                  <Row>
                    <Col span="18" style={{ top: "5px" }}>
                      Numero de suscriptores
                    </Col>
                    <Col span="4">
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444"
                          stroke="#7084B1"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </Col>
                  </Row>
                </>
              ),
              dataIndex: "count",
              key: "count",
              sorter: {
                compare: (a, b) => a.count - b.count,
              },
              render: (int) => {
                if (int > 60) {
                  return int.toLocaleString("en-US", {
                    notation: "compact",
                    compactDisplay: "short",
                  })
                } if (int < 60) {
                  return "<60"
                }
              },
              showSorterTooltip: false,
            },
            {
              title: "Acciones",
              key: "operation",
              render: (text, record, index) => (
                <Dropdown
                  overlay={() => (
                    <Menu
                      onClick={onClickMenuExports(record)}
                      items={[
                        {
                          key: "1",
                          label: "Editar",
                          icon: (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 18 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                id="Fill 1"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z"
                                fill="black"
                              />
                              <path
                                id="Clip 4"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M1.33398 2H12.7877V14.6264H1.33398V2Z"
                                fill="white"
                              />
                              <path
                                id="Fill 3"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z"
                                fill="black"
                              />
                              <path
                                id="Fill 6"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z"
                                fill="black"
                              />
                            </svg>
                          ),
                        },
                        {
                          key: "2",
                          label: "Duplicar",
                          icon: (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.21509 2.06496C4.04668 2.06496 3.07771 3.01068 3.05022 4.17698V11.8097C3.02978 12.9703 3.95084 13.9231 5.10304 13.9428L5.20663 13.9421H10.8528C12.0099 13.9294 12.9535 12.9732 12.9521 11.8104V5.46871L9.68647 2.06496H5.22354H5.21509ZM5.09267 14.9998C3.34992 14.9695 1.96305 13.534 1.99265 11.8004V4.16421C2.03282 2.41582 3.47677 1.00781 5.21247 1.00781H5.22515H9.91077C10.0545 1.00781 10.1919 1.0663 10.292 1.1699L13.8621 4.89006C13.9558 4.98801 14.0087 5.11979 14.0087 5.2558V11.8096C14.0115 13.5495 12.5978 14.9808 10.8579 14.9991L5.09267 14.9998Z"
                                fill="#181D2E"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M13.477 5.92169H11.5362C10.2459 5.91817 9.19727 4.86674 9.19727 3.57853V1.52853C9.19727 1.23678 9.43405 1 9.7258 1C10.0175 1 10.2543 1.23678 10.2543 1.52853V3.57853C10.2543 4.28606 10.8301 4.86251 11.5376 4.86463H13.477C13.7687 4.86463 14.0055 5.10141 14.0055 5.39316C14.0055 5.68491 13.7687 5.92169 13.477 5.92169"
                                fill="#181D2E"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9.59809 9.21942H6.14572C5.85397 9.21942 5.61719 8.98264 5.61719 8.69089C5.61719 8.39914 5.85397 8.16235 6.14572 8.16235H9.59809C9.88984 8.16235 10.1266 8.39914 10.1266 8.69089C10.1266 8.98264 9.88984 9.21942 9.59809 9.21942"
                                fill="#181D2E"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M7.87228 10.9462C7.58053 10.9462 7.34375 10.7094 7.34375 10.4176V6.96457C7.34375 6.67282 7.58053 6.43604 7.87228 6.43604C8.16403 6.43604 8.40081 6.67282 8.40081 6.96457V10.4176C8.40081 10.7094 8.16403 10.9462 7.87228 10.9462"
                                fill="#181D2E"
                              />
                            </svg>
                          ),
                        },
                        /* Botón para iniciar exportación, comentado por solicitud de cliente
                        {
                          key: "3",
                          label: "Iniciar",
                          icon: (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.14388 3.14806C5.38783 3.14806 3.14587 5.39003 3.14587 8.14608C3.14587 10.9021 5.38783 13.1441 8.14388 13.1441C10.8999 13.1441 13.1419 10.9021 13.1419 8.14608C13.1419 5.39003 10.8999 3.14806 8.14388 3.14806M8.14388 14.0009C4.91545 14.0009 2.28906 11.3745 2.28906 8.14608C2.28906 4.91765 4.91545 2.29126 8.14388 2.29126C11.3723 2.29126 13.9987 4.91765 13.9987 8.14608C13.9987 11.3745 11.3723 14.0009 8.14388 14.0009"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.50787 6.91028C7.40677 7.48205 7.40505 8.8478 7.50616 9.39044C8.00767 9.21337 9.0941 8.49937 9.38655 8.14579C9.09524 7.8025 8.02252 7.10335 7.50787 6.91028V6.91028ZM7.33278 10.289C7.17855 10.289 7.03232 10.245 6.91294 10.1262C6.82098 10.0353 6.57536 9.79259 6.57422 8.19379C6.57365 6.49618 6.82898 6.24371 6.91294 6.15974C7.21168 5.86386 7.69834 6.06664 7.90569 6.15346C8.20272 6.27741 10.287 7.34499 10.287 8.14353C10.287 8.8918 8.4112 9.93824 7.93197 10.1382C7.74518 10.2158 7.53212 10.289 7.33278 10.289V10.289Z"
                                fill="black"
                              />
                            </svg>
                          ),
                        },
                      */
                        {
                          key: "4",
                          label: "Detener",
                          icon: (
                            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="-1 0 4 4" fill="none">
                              <path d="M0.5 4V0M3.5 4V0" stroke="black" />
                            </svg>
                          ),
                        },
                        {
                          key: "5",
                          label: "Eliminar",
                          icon: (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z"
                                fill="black"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z"
                                fill="black"
                              />
                            </svg>
                          ),
                        }
                      ]}
                    />
                  )}
                >
                  <a className='optionsButton'>
                    <svg
                      width="3"
                      height="10"
                      viewBox="0 0 3 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="1.5" cy="1" r="1" fill="#181D2E" />
                      <circle cx="1.5" cy="5" r="1" fill="#181D2E" />
                      <circle cx="1.5" cy="9" r="1" fill="#181D2E" />
                    </svg>
                  </a>
                </Dropdown>
              ),
            },
          ]}
          expandable={{
            expandedRowRender,
            /*defaultExpandedRowKeys: ['0'],*/
          }}
          dataSource={data}
          size="small"
          loading={showLoadingData}
        />

      </>
      )
        :
        <div>{showLoadingData ? <h3>Cargando Exportaciones.</h3> : <h3>No hay exportaciones creadas.</h3>}</div>
      }

      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalOpenUpdate}
        onOk={handleChange}
        onCancel={handleCancel}
      >
        <p>
          ¿Estás seguro que deseas cambiar los permisos de la audiencia?
        </p>
      </Modal>
      <Modal
        clasName="ModalGA"
        title="Atención"
        open={isModalOpenDelete}
        onOk={DeleteExport}
        onCancel={handleCancel}
      >
        <p>
          ¿Estás seguro que deseas eliminar la exportación?
        </p>
      </Modal>
    </div>
  );
};

export default Exports;
