import { useEffect, useState, useRef } from 'react'

import {
  Col,
  Row,
  Space,
  Table,
  Button,
  Input,
  Menu,
  Dropdown,
  Modal,
} from 'antd'
import { SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import './ListUsers.css'
import { API } from 'aws-amplify';

const { Search } = Input;

const ListUsers = props => {
  const [data, setData] = useState()
  const [grid, setGrid] = useState()
  const [typeGrid, setTypeGrid] = useState('list')
  const [file, setFile] = useState()
  const [deleteUsers, setDeleteUsers] = useState(false)
  const [dataUsers, setDataUsers] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Buscar`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block"
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px",
              backgroundColor: "#391EDE"
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              borderRadius: "10px"
            }}
          >
            Limpiar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (dataIndex === 'groups') {
        return record.groups.items.some(item =>
          item.group.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
      }
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) => {
      if (searchedColumn === dataIndex) {
        if (dataIndex === 'groups') {
          const filteredGroups = record.groups.items.filter(item =>
            item.group.name.toLowerCase().includes(searchText.toLowerCase())
          );
          return filteredGroups.map(group => group.group.name).join(', ');
        } else {
          return text;
        }
      } else {
        if (dataIndex === 'groups') {
          return record.groups.items.map(group => group.group.name).join(', ');
        } else {
          return text;
        }
      }
    }
  });
  

  const getData = async (input) => {
    let nextToken = null;
    let allUsers = [];
    do {
      const allTodos = await API.graphql({
        query: `
          query ListUsers(
            $filter: ModelUserFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
              items {
                id
                createdAt
                rol
                name
                lastName
                username
                restrictions {
                  items {
                    id
                    restrictionID
                  }
                }
                groups {
                  items {
                    group {
                      name
                    }
                  }
                }
              }
              nextToken
            }
          }
        `,
        variables: {
          filter: input,
          limit: 1000,
          nextToken,
        },
      });
      const items = allTodos.data.listUsers.items;
      allUsers = allUsers.concat(items);
      nextToken = allTodos.data.listUsers.nextToken;
    } while (nextToken !== null);
    setDataUsers(allUsers);
  };
  

  useEffect(() => {
    const input = {
      is_deleted: { eq: false }
    }
    getData(input)
  }, [])//deleteUsers, updateUsers, view, createUsers

  const onSearch = (value) => {
    let input = {
      is_deleted: { eq: false }
    }
    if (value !== '') input.username = { contains: value }
    getData(input)
  };

  useEffect(() => {
    if (data) {
      const onClickMenuAcciones = (key, record) => {
        const { id } = record
        if (key === '1') navigate(`/user/${id}?mode=upd`)
        else if (key === '2') navigate(`/user/${id}?mode=dlt`)
        else navigate(`/user/${id}?mode=pswd`)
      }

      const menu = (record) => (
        <Menu
          onClick={({ key }) => onClickMenuAcciones(key, record)}
          items={[
            {
              key: '1',
              label: 'Ver / Editar',
              icon: <svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Fill 1" fillRule="evenodd" clipRule="evenodd" d="M13.8334 14.6265H8.99805C8.72205 14.6265 8.49805 14.4025 8.49805 14.1265C8.49805 13.8505 8.72205 13.6265 8.99805 13.6265H13.8334C14.1094 13.6265 14.3334 13.8505 14.3334 14.1265C14.3334 14.4025 14.1094 14.6265 13.8334 14.6265Z" fill="black" /> <path id="Clip 4" fillRule="evenodd" clipRule="evenodd" d="M1.33398 2H12.7877V14.6264H1.33398V2Z" fill="white" /> <path id="Fill 3" fillRule="evenodd" clipRule="evenodd" d="M8.74083 3.34443L2.46417 11.1944C2.35017 11.3371 2.30817 11.5211 2.35017 11.6978L2.80417 13.6211L4.83017 13.5958C5.02283 13.5938 5.20083 13.5078 5.31883 13.3611C7.4635 10.6778 11.5522 5.56176 11.6682 5.41176C11.7775 5.23443 11.8202 4.98376 11.7628 4.74243C11.7042 4.4951 11.5502 4.2851 11.3282 4.1511C11.2808 4.11843 10.1575 3.24643 10.1228 3.2191C9.70017 2.88043 9.0835 2.9391 8.74083 3.34443ZM2.4095 14.6264C2.17817 14.6264 1.97683 14.4678 1.92283 14.2418L1.37683 11.9278C1.26417 11.4484 1.37617 10.9538 1.6835 10.5698L7.9635 2.7151C7.96617 2.71243 7.96817 2.7091 7.97083 2.70643C8.6595 1.8831 9.90483 1.76176 10.7448 2.43576C10.7782 2.46176 11.8935 3.32843 11.8935 3.32843C12.2988 3.56976 12.6155 4.0011 12.7355 4.51176C12.8548 5.0171 12.7682 5.53843 12.4902 5.97909C12.4695 6.01176 12.4515 6.03976 6.0995 13.9864C5.7935 14.3678 5.33483 14.5898 4.84217 14.5958L2.41617 14.6264H2.4095Z" fill="black" /> <path id="Fill 6" fillRule="evenodd" clipRule="evenodd" d="M10.8163 7.78983C10.7096 7.78983 10.6029 7.75583 10.5116 7.6865L6.87693 4.8945C6.65826 4.7265 6.61693 4.41316 6.78493 4.19316C6.9536 3.9745 7.26693 3.93383 7.48626 4.10183L11.1216 6.89316C11.3403 7.06116 11.3816 7.37516 11.2129 7.5945C11.1149 7.7225 10.9663 7.78983 10.8163 7.78983Z" fill="black" /></svg>,
            },
            {
              key: '2',
              label: 'Eliminar',
              icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M8.16359 14.6666C7.26025 14.6666 6.37959 14.6566 5.50825 14.6386C4.39358 14.6166 3.62225 13.8939 3.49625 12.7526C3.28625 10.8593 2.92692 6.3966 2.92358 6.35194C2.90092 6.0766 3.10625 5.83527 3.38159 5.81327C3.65292 5.80594 3.89825 5.9966 3.92025 6.27127C3.92359 6.3166 4.28225 10.7639 4.49025 12.6426C4.56159 13.2913 4.91158 13.6259 5.52892 13.6386C7.19559 13.6739 8.89625 13.6759 10.7296 13.6426C11.3856 13.6299 11.7403 13.3019 11.8136 12.6379C12.0203 10.7753 12.3803 6.3166 12.3843 6.27127C12.4063 5.9966 12.6496 5.8046 12.9223 5.81327C13.1976 5.83594 13.4029 6.0766 13.3809 6.35194C13.3769 6.39727 13.0156 10.8713 12.8076 12.7479C12.6783 13.9126 11.9089 14.6213 10.7476 14.6426C9.85892 14.6579 9.00159 14.6666 8.16359 14.6666Z" fill="black" /> <path fillRule="evenodd" clipRule="evenodd" d="M13.8053 4.65942H2.5C2.224 4.65942 2 4.43542 2 4.15942C2 3.88342 2.224 3.65942 2.5 3.65942H13.8053C14.0813 3.65942 14.3053 3.88342 14.3053 4.15942C14.3053 4.43542 14.0813 4.65942 13.8053 4.65942Z" fill="black" /> <path fillRule="evenodd" clipRule="evenodd" d="M11.6264 4.6595C10.8677 4.6595 10.2091 4.11883 10.0597 3.37483L9.89774 2.56416C9.86374 2.44083 9.72307 2.3335 9.56307 2.3335H6.74107C6.58107 2.3335 6.4404 2.44083 6.39973 2.59483L6.2444 3.37483C6.09573 4.11883 5.4364 4.6595 4.67773 4.6595C4.40173 4.6595 4.17773 4.4355 4.17773 4.1595C4.17773 3.8835 4.40173 3.6595 4.67773 3.6595C4.96173 3.6595 5.2084 3.45683 5.2644 3.17816L5.4264 2.3675C5.59107 1.74616 6.12907 1.3335 6.74107 1.3335H9.56307C10.1751 1.3335 10.7131 1.74616 10.8711 2.3375L11.0404 3.17816C11.0957 3.45683 11.3424 3.6595 11.6264 3.6595C11.9024 3.6595 12.1264 3.8835 12.1264 4.1595C12.1264 4.4355 11.9024 4.6595 11.6264 4.6595Z" fill="black" /> </svg>,
            },
            {
              key: '3',
              label: 'Cambiar contraseña',
              icon: <svg width="11" height="11" viewBox="3 4 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
              <path d="M17 10.25H16.75V8C16.75 6.74022 16.2496 5.53204 15.3588 4.64124C14.468 3.75045 13.2598 3.25 12 3.25C10.7402 3.25 9.53204 3.75045 8.64124 4.64124C7.75045 5.53204 7.25 6.74022 7.25 8V10.25H7C6.27065 10.25 5.57118 10.5397 5.05546 11.0555C4.53973 11.5712 4.25 12.2707 4.25 13V18C4.25 18.7293 4.53973 19.4288 5.05546 19.9445C5.57118 20.4603 6.27065 20.75 7 20.75H17C17.7293 20.75 18.4288 20.4603 18.9445 19.9445C19.4603 19.4288 19.75 18.7293 19.75 18V13C19.75 12.2707 19.4603 11.5712 18.9445 11.0555C18.4288 10.5397 17.7293 10.25 17 10.25ZM8.75 8C8.75 7.13805 9.09241 6.3114 9.7019 5.7019C10.3114 5.09241 11.138 4.75 12 4.75C12.862 4.75 13.6886 5.09241 14.2981 5.7019C14.9076 6.3114 15.25 7.13805 15.25 8V10.25H8.75V8ZM18.25 18C18.25 18.3315 18.1183 18.6495 17.8839 18.8839C17.6495 19.1183 17.3315 19.25 17 19.25H7C6.66848 19.25 6.35054 19.1183 6.11612 18.8839C5.8817 18.6495 5.75 18.3315 5.75 18V13C5.75 12.6685 5.8817 12.3505 6.11612 12.1161C6.35054 11.8817 6.66848 11.75 7 11.75H17C17.3315 11.75 17.6495 11.8817 17.8839 12.1161C18.1183 12.3505 18.25 12.6685 18.25 13V18Z" fill="black"/>
              </svg>,
            }
          ]}
        />
      )

      const columns = [
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Usuario
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,

          dataIndex: 'username',
          key: 'id',
          sorter: (a, b) => {
            return a.username.localeCompare(b.username);
          },
          showSorterTooltip: false,
          ...getColumnSearchProps("username")
          //render: text => <a>{text}</a>,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Nombre
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,

          dataIndex: 'name',
          key: 'id',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name);
          },
          showSorterTooltip: false,
          //render: text => <a>{text}</a>,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Apellido
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,

          dataIndex: 'lastName',
          key: 'id',
          sorter: (a, b) => {
            return a.lastName.localeCompare(b.lastName);
          },
          showSorterTooltip: false,
          //render: text => <a>{text}</a>,
        },
        {
          title: 
            <>
            <Row>
              <Col span="18" style={{ top: '15px' }}>
                Filtros Fecha/Hora
              </Col>
              <Col span="4">
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
              </Col>
            </Row>
          </>,
          dataIndex: 'restrictions',
          render: (text, record) => (
            <span>{record.restrictions && record.restrictions.items && record.restrictions.items.length > 0 ? 'No' : 'Sí'}</span>
          ),
          sorter: (a, b) => {
            const hasRestrictionsA = a.restrictions && a.restrictions.items && a.restrictions.items.length > 0;
            const hasRestrictionsB = b.restrictions && b.restrictions.items && b.restrictions.items.length > 0;
            return hasRestrictionsA - hasRestrictionsB;
          },
          showSorterTooltip: false,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Rol
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'rol',
          render: text => !text ? 'NA' : text,
          sorter: (a, b) => {
            return a.rol.localeCompare(b.rol);
          },
          ...getColumnSearchProps("rol"),
          showSorterTooltip: false,
        },
        {
          title:             
          <>
            <Row>
              <Col span="18" style={{ top: '15px' }}>
                Grupo
              </Col>
              <Col span="4">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
              </Col>
            </Row>
          </>,
          dataIndex: 'groups',
          render: (groups) => {
            if (groups && groups.items && groups.items.length > 0) {
              return groups.items.map(item => item.group.name).join(', ');
            }
            return 'NA';
          },
          sorter: (a, b) => {
            const groupsA = a.groups.items.map(item => item.group.name).join(', ');
            const groupsB = b.groups.items.map(item => item.group.name).join(', ');
            return groupsA.localeCompare(groupsB);
          },
          ...getColumnSearchProps("groups"),
          showSorterTooltip: false,
        },
        {
          title:
            <>
              <Row>
                <Col span="18" style={{ top: '15px' }}>
                  Fecha de creación
                </Col>
                <Col span="4">
                  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M6.6 4.5V20.5M6.6 4.5L10.2 8.05556M6.6 4.5L3 8.05556M17.4 20.5V4.5M17.4 20.5L21 16.9444M17.4 20.5L13.8 16.9444" stroke="#7084B1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                </Col>
              </Row>
            </>,
          dataIndex: 'createdAt',
          defaultSortOrder: 'descend',
          ...getColumnSearchProps("createdAt"),
          render: (text) => text.substring(0, 10),
          sorter: {
            compare: (a, b) =>
              Date.parse(a.createdAt) - Date.parse(b.createdAt),
          },
          showSorterTooltip: false,
        },
        {
          title: 'Acciones',
          dataIndex: 'permisos',

          render: (_, record) => (
            <Dropdown overlay={() => menu(record)}>
              <a className='optionsButton'>
                <svg width="3" height="10" viewBox="0 0 3 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="1.5" cy="1" r="1" fill="#181D2E" /> <circle cx="1.5" cy="5" r="1" fill="#181D2E" /> <circle cx="1.5" cy="9" r="1" fill="#181D2E" /> </svg>
              </a>
            </Dropdown>
          )
        }
      ]
      setGrid(<Table size="small" columns={columns} dataSource={data} style={{ width: '100%' }} />)
    }
  }, [data, typeGrid])


  useEffect(() => {
    setData(dataUsers)
  }, [dataUsers])

  const showModal = () => {
    const changePassword = async (input) => {
      const usuario = await API.graphql({
        query: `
              mutation MyMutation($input: changePasswordInput) {
                changePassword(input: $input) {
                      name
                  }
              }`,
        variables: { input },
      });
      if (usuario) navigate("/users");
    };
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


  return (
    <div className='ListUsersComponent'>
      <Row className='MenuListUsers' align="middle">
        <Col span={7}
          className='TituloBoldGA Col1MenuExports'
          style={{ textTranform: 'uppercase' }}
        >
          Usuarios
        </Col>
        <Col span={2} offset={2}>
          <Button
            style={{ marginLeft: "450px" }}
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => navigate('/user')}
          >
            Nuevo
          </Button>
          </Col>
          <Col span={2}>
          <Button
            style={{ marginLeft: "450px"}}
            className="SubtituloRegularGA Button1"
            type="primary"
            onClick={() => navigate('/usersgroup')}
          >
            Lista de grupos
          </Button>
        </Col>
      </Row>
      {grid && <Row>{grid}</Row>}
    </div>
  )
}


export default ListUsers
