import { Divider, Row } from "antd";

export function ChartDivider({ title, stitle }) {
  return (
    <>
      <Row
        align="middle"
        justify="space-between"
        style={{
          backgroundColor: "white",
          marginRight: 60,
          padding: 0,
        }}
        gutter={5}
      >
        <Divider
          className="TituloRegularGA"
          orientation="left"
          orientationMargin={100.5}
          style={{ textAlign: "left", fontSize: 20 }}
        >
          {title}
          <br />
          <span className="TituloBoldGA">{stitle}</span>
        </Divider>
      </Row>
    </>
  );
}
export function SubtitleDivider({ subtitle }) {
  return (
    <>
      <div
        className="SubtituloCharts"
        style={{ color: "#0D33B9", marginLeft: 100.5, fontSize: 15.5 }}
      >
        {subtitle}
      </div>
      <br />
    </>
  );
}
