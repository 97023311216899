import { Line } from "@ant-design/plots";
import { Card, Divider, Row, Col, Avatar, Typography, Table } from "antd";
import { useState, useEffect } from "react";
import { SubtitleDivider } from "./LineChart/ChartsDivider";
const { Title, Text } = Typography;

const LineChart = ({ data, showLoadingData, filter, nameChart}) => {

  // setear en Audience.jsx setShowLoadingData
  // Pasar argumento showLoadingData
  // Evaluar filtros para pattern de config linea 155
  // Tipo/Orientación (Horizontal o Vertical)
  // xField y yField

  const [config, setConfig] = useState();
  const [maxValue, setMaxValue] = useState()
  const [maxValueNewAudience, setMaxValueNewAudience] = useState(0)
  const [maxValueExternalBD, setMaxValueExternalBD] = useState(0)



  useEffect(() => {
    if (data.total) {

      const indices = Object.keys(data)
      let dataChart = []

      let columns = indices.map((item, index) => {
        // Se evalua que existan datos en las diferentes audiencias
        if (data[item]) {
          // Se recorren los items para evaluar el tipo de audiencia y sus valores y porcentajes
          data[item].forEach(indicador => {
            indicador.type = item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa"
          })

          dataChart.push(...data[item])

          return ({
            title: item === 'total' ? "Audiencia" : item === 'newAudience' ? 'Nueva audiencia' : "BD externa",
            dataIndex: `value${index}`,
            key: "value",
            align: "center",
            render(text, record) {
              return {
                props: {
                  className: "TextCharts",
                  style: { color: item === 'total' ? "#391EDE" : item === 'newAudience' ? "#FF596F" : "#00C6A2" },
                },
                children: <div>{text}</div>,
              };
            },
          })
        }
      })

      let maxValue = dataChart.map(({subscribers})=>subscribers)
      maxValue = Math.max(...maxValue)
      setMaxValue(maxValue.toLocaleString("en-US",{ notation: "compact", compactDisplay: "short" }))

      if (dataChart.filter(({ type }) => type == "Nueva audiencia").length === 0) {
        setMaxValueNewAudience(0)
      } else {
        let maxValueNewAudience = dataChart
          .filter(({ type }) => type == "Nueva audiencia")
          .map(({ subscribers }) => subscribers);
      
        maxValueNewAudience = Math.max(...maxValueNewAudience);
        setMaxValueNewAudience(
          maxValueNewAudience.toLocaleString("en-US", {
            notation: "compact",
            compactDisplay: "short",
          })
        );
      }

      if (dataChart.filter(({ type }) => type == "BD externa").length === 0) {
        setMaxValueExternalBD(0)
      } else {
        let maxValueExternalBD = dataChart
          .filter(({ type }) => type == "BD externa")
          .map(({ subscribers }) => subscribers);
      
          maxValueExternalBD = Math.max(...maxValueExternalBD);
        setMaxValueExternalBD(
          maxValueExternalBD.toLocaleString("en-US", {
            notation: "compact",
            compactDisplay: "short",
          })
        );
      }

      let config = {
        data: dataChart,
        xField: "value_filter",
        yField: "subscribers",
        yAxis: {
          label: {
            formatter: (evt, item) => `${evt.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g,'$1,')}`,
          },
        },
        seriesField: "type",
        colorfield: "type",
        color: ({type})=>{
          if(type==="Audiencia"){
            return "#391EDE"
          }
          if(type==="Nueva audiencia"){
            return "#FF596F"
          }
          if(type==="BD externa"){
            return "#00C6A2"
          }
        },
        lineStyle: ({ type }) => {
          if (type === "Audiencia") {
            return {
              lineDash: [3, 6],
            };
          }
        },
        point: {
          shape: ({ category }) => {
            return category === "Gas fuel" ? "square" : "circle";
          },
        },
        legend: {
          position: "top",
          selected: {
            //"Nueva audiencia": false,
            //"BD externa": false,
          },
        },
        slider: {
          trendCfg: {
            isArea: true,
          },
        },
        tooltip: {
          showMarkers: false,
        },
        interactions: [
          {
            type: "element-active",
          },
          {
            type: "element-highlight-by-color",
          },
        ],
      };
      setConfig(
        <Line
          {...config}
        />
      );
    }
  }, [data])//[data])

  return (
    <>
      <br />
      <SubtitleDivider subtitle={nameChart} />
      <Row
        align="middle"
        justify="space-between"
        style={{
          backgroundColor: "white",
          marginRight: 60,
          padding: 5,
        }}
        gutter={5}
      >
        <Col
          span={4}
          push={2}
          className="TextCharts"
          style={{ color: "#181D2E" }}
        >
          <Avatar
            size={50}
            className="CifraGeneralGA"
            style={{ color: "#FFFFFF", backgroundColor: "#391EDE" }}
            loading={showLoadingData}
          >
            {maxValue}{" "}
          </Avatar>{" "}
          Audiencia
          <br />
          <br />
          {
          maxValueNewAudience !==0 ? <>
            <Avatar
            size={50}
            className="CifraGeneralGA"
            style={{ color: "#FFFFFF", backgroundColor: "#FF596F" }}
            loading={showLoadingData}
          >
            {maxValueNewAudience}{" "}
          </Avatar>{" "}{"Nueva audiencia"}
          </> : null 
          }
          {
          maxValueExternalBD !==0 ? <>
            <Avatar
            size={50}
            className="CifraGeneralGA"
            style={{ color: "#FFFFFF", backgroundColor: "#00C6A2" }}
            loading={showLoadingData}
          >
            {maxValueExternalBD}{" "}
          </Avatar>{" "}{"BD externa"}
          </> : null 
          }
          <br />
          <br />
          
        </Col>
        <Col span={18} push={0}>
          <Card
            style={{
              width: "100%",
              padding: 0,
              borderRadius: 20,
              backgroundColor: "#F8FAFF",
              boxShadow: "2px 2px 7px rgba(0, 0, 0, 0.1)",
            }}
            loading={showLoadingData}
          >
            <div style={{ height: 200 }}>{config && config}</div>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default LineChart;