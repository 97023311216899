import { Tabs, Input, Space, Menu, Select, Tag, Col, Row, Table, DatePicker, Divider, Calendar } from 'antd';
import React, { useState, useEffect, useContext } from 'react';
import './Filters.css'
import Comportamientos from './DefinicionDeLaAudiencia/Comportamientos'
import Ubicaciones from './DefinicionDeLaAudiencia/Ubicaciones'
import Demograficos from './FiltrarAudiencia/Demograficos'
import Dispositivos from './FiltrarAudiencia/Dispositivos'
import DateNavigation from './FiltrarAudiencia/DateNavigation'
import DataBaseExternal from './FiltrarAudiencia/DataBaseExternal'
import { UserContext } from '../../GestorContext'
import { iconUsers, iconFilters } from '../../Helpers/Icons'

const Filters = (
  {
    filters,
    setFilters,
    comportamientos,
    setComportamientos,
    ubicaciones,
    setUbicaciones,
    genero,
    setGenero,
    ingreso,
    setIngreso,
    edad,
    setEdad,
    marca,
    setMarca,
    tipo,
    setTipo,
    sistemaOperativo,
    setSistemaOperativo,
    dateNavigationRange,
    hourNavigationRange,
    setDateNavigationRange,
    setHourNavigationRange,
    dataBaseExternal,
    setDataBaseExternal,
    setDBExternalName
  }
) => {
  const [view, setView] = useState('Comportamientos')
  const [rolUser, setRolUser] = useState()
  const [filterRestriction, setFilterRestriction] = useState()
  

  const userContext = useContext(UserContext)

  useEffect(() => {
    if (userContext) {
      const groups = userContext.signInUserSession.idToken.payload['cognito:groups']
      const restrictions = userContext.signInUserSession.idToken.payload['custom:hour']
      setFilterRestriction(restrictions)
      setRolUser(groups[0])
    }
  }, [])

  return (
    <>
      <Row className="Columnas1">
        <Col span={7}>
          {iconUsers}
          <span className="SubtituloRegularGA definiciondelaaudiencia">Definicion de la Audiencia</span>
        </Col>
        <Col span={1} className="FiltrarAudiencia">
        </Col>
        <Col span={16}>
          {iconFilters}
          <span className="SubtituloRegularGA">Filtrar Audiencia</span>
        </Col>
      </Row>
      <Row className="Columnas2">
        <Col span={4}>
          <div
            className={view == 'Comportamientos' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"}
            onClick={() => setView('Comportamientos')}
          >
            <div
              role="tab"
              aria-selected="true"
              className="ant-tabs-tab-btn"
              tabIndex="0"
              id="rc-tabs-2-tab-1"
              aria-controls="rc-tabs-2-panel-1"
            >
              Comportamientos
            </div>
          </div>
        </Col>
        <Col span={3}>
          <div className={view == 'Ubicaciones' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"} onClick={() => setView('Ubicaciones')}><div role="tab" aria-selected="true" className="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-2-tab-1" aria-controls="rc-tabs-2-panel-1">Ubicaciones</div></div>
        </Col>
        <Col span={1} className="FiltrarAudiencia">
        </Col>
        <Col span={4}>
          <div className={view == 'Demograficos' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"} onClick={() => setView('Demograficos')}><div role="tab" aria-selected="true" className="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-2-tab-1" aria-controls="rc-tabs-2-panel-1">Demograficos</div></div>
        </Col>
        <Col span={4}>
          <div className={view == 'Dispositivos' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"} onClick={() => setView('Dispositivos')}><div role="tab" aria-selected="true" className="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-2-tab-1" aria-controls="rc-tabs-2-panel-1">Dispositivos</div></div>
        </Col>
        {filterRestriction == "Enabled" ?
        <Col span={4}>
          <div className={view == 'DateNavigation' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"} onClick={() => setView('DateNavigation')}><div role="tab" aria-selected="true" className="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-2-tab-1" aria-controls="rc-tabs-2-panel-1">Fecha de Navegación</div></div>
        </Col>
        :
        null
        }    
        {
          (rolUser === 'Admin' || rolUser === 'Company') ?
            <Col span={4}>
              <div className={view == 'DataBaseExternal' ? "ant-tabs-tab ant-tabs-tab-active" : "ant-tabs-tab"} onClick={() => setView('DataBaseExternal')}><div role="tab" aria-selected="true" className="ant-tabs-tab-btn" tabIndex="0" id="rc-tabs-2-tab-1" aria-controls="rc-tabs-2-panel-1">Seleccionar BD externa</div></div>
            </Col>
            :
            null
        }
      </Row>
      <div className="ant-tabs-content-holder" style={view == 'Comportamientos' ? { display: 'block' } : { display: 'none' }}>
        <Comportamientos
          filters={filters}
          setFilters={setFilters}
          comportamientos={comportamientos}
          setComportamientos={setComportamientos}
        />
      </div>
      <div className="ant-tabs-content-holder" style={view == 'Ubicaciones' ? { display: 'block' } : { display: 'none' }}>
        <Ubicaciones
          filters={filters}
          setFilters={setFilters}
          ubicaciones={ubicaciones}
          setUbicaciones={setUbicaciones}
        />
      </div>
      <div className="ant-tabs-content-holder" style={view == 'Demograficos' ? { display: 'block' } : { display: 'none' }}>
        <Demograficos
          filters={filters}
          setFilters={setFilters}
          genero={genero}
          setGenero={setGenero}
          ingreso={ingreso}
          setIngreso={setIngreso}
          edad={edad}
          setEdad={setEdad}
        />
      </div>
      <div className="ant-tabs-content-holder" style={view == 'Dispositivos' ? { display: 'block' } : { display: 'none' }}>
        <Dispositivos
          filters={filters}
          setFilters={setFilters}
          marca={marca}
          setMarca={setMarca}
          tipo={tipo}
          setTipo={setTipo}
          sistemaOperativo={sistemaOperativo}
          setSistemaOperativo={setSistemaOperativo}
        />
      </div>
      <div className="ant-tabs-content-holder" style={view == 'DateNavigation' ? { display: 'block' } : { display: 'none' }}>
        <DateNavigation
          filters={filters}
          setFilters={setFilters}
          dateNavigationRange={dateNavigationRange}
          setDateNavigationRange={setDateNavigationRange}
          hourNavigationRange={hourNavigationRange}
          setHourNavigationRange={setHourNavigationRange}
        />
      </div>
      <div className="ant-tabs-content-holder" style={view == 'DataBaseExternal' ? { display: 'block' } : { display: 'none' }}>
        <DataBaseExternal dataBaseExternal={dataBaseExternal} setDataBaseExternal={setDataBaseExternal} setDBExternalName={setDBExternalName}/>
      </div>
    </>
  );
};

export default Filters;
